import React from "react";
import Header from "../utils/header";
import Footer from "../utils/footer";
import { Helmet } from "react-helmet";
import Blog3 from "../components/blog3";

const Blog3Page = () => {
  return (
    <div>
      <Helmet>
        <title>Docusynth - Blog3</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
      </Helmet>
      <Header />
      <Blog3 />
      <Footer display="none" />
    </div>
  );
};

export default Blog3Page;
