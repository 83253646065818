import React from "react";
import Header from "../utils/header";
import Blog1 from "../components/blog1";
import Footer from "../utils/footer";
import { Helmet } from "react-helmet"

const Blog1Page = () => {
  return (
    <div>
    <Helmet>
        <title>Docusynth - Blog1</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
    </Helmet>
      <Header />
      <Blog1 />
      <Footer display="none"/>
    </div>
  );
};

export default Blog1Page;
