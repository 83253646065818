import React from "react";
import VideoBannerImage from "../../assets/images/about/div.background.png";
import { Fade } from "react-reveal";

const IndustryInsider = () => {
  return (
    <div className="pb-[100px] xl:pb-[150px]">
      <div className="container-class">
        <div className="flex justify-center flex-col items-center">
          <Fade left>
            <h1 className="text-[#222] text-center font-medium text-[30px] md:text-[36px] xl:text-[40px]">
              Created by an industry insider
              <span className="text-[#00D588]">.</span>
            </h1>
            <div>
              <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pt-[20px] w-full xl:w-[496px] text-center">
                Is bill line item data entry overwhelming? Eliminate the need
                for any manual data entry with
              </p>
            </div>
          </Fade>
        </div>
      </div>
      <div className="industry-insider-bg mt-[350px]  mb-[80px] xl:mb-[0px] relative">
        <div
          className="flex items-center justify-center absolute top-[-250px] right-0 left-0 mx-auto max-w-[90%] xl:max-w-[1200px]"
          // style={{ maxWidth: "70%" }}
        >
          <div
            className="relative w-full h-[540px] rounded-[10px] overflow-hidden"
            style={{
              backgroundImage: `url(${VideoBannerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <iframe
              width="100%"
              height="100%"
              // src="https://www.youtube.com/embed/RB1uDVnhVq0?si=d0DlD-AjqmaPlnEF"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              style={{ backgroundColor: "transparent" }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryInsider;
