import React from "react";
import AboutImage from "../../assets/images/about/Group 5419.png";
import { Fade } from "react-reveal";
const Banner = () => {
  return (
    <div className="container-class">
      <div className="flex flex-col xl:flex-row xl:items-center justify-between items-start pt-[80px] xl:pt-[130px] pb-[80px] xl:pb-[170px]">
        <Fade left>
          <div className="w-full xl:w-[50%]">
            <img src={AboutImage} alt="ProcedureImage" className="w-full" />
          </div>
        </Fade>
        <Fade right>
          <div className=" w-full xl:w-[50%] flex flex-col pt-[30px] xl:pt-[0px] items-start xl:items-end">
            <h2 className="text-[#222] text-[24px] md:text-[30px] xl:text-[40px] font-medium w-full xl:w-[525px]">
              Transforming the accounts payable process, one company at a time
              <span className="text-[#00D588]">.</span>
            </h2>
            <p className="text-[#222] w-full xl:w-[510px] pt-[20px] text-[14px] md:text-[16px] xl:text-[18px] font-light">
              Our AP automation solutions are helping more businesses increase
              efficiency, visibility and control in the AP process than ever
              before. Seamlessly integrated with your current accounting system
              or ERP, and connected to one of the largest supplier networks,
              you’re able to process invoices and make payments without touching
              any paper.
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Banner;
