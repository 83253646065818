import React from "react";
import "./assets/style/style.css";
import RoutesPages from "./routes";
import { ToastContainer } from "react-toastify";

const App = () => {
  
  return (
    <div>
      <ToastContainer />
      <RoutesPages />
    </div>
  );
};

export default App;
