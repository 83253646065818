import React from "react";
const TermsAndConditions = () => {
  return (
    <div className="container-class pt-[80px]">
      <div className="flex flex-col items-center">
        <div className="w-[70%]">
          <h1 className="text-[#222] text-[30px] md:text-[36px] xl:text-[40px] font-medium text-center">
            DocuSynth Terms of Service
          </h1>
          <p className="text-[#00D588] text-[14px] md:text-[16px] xl:text-[18px]  font-medium text-center pt-[50px]">
            Last Updated: [05/17/2024]
          </p>
        </div>
      </div>
      <div className="pt-[100px]">
        <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px]  font-light">
          Welcome to DocuSynth AI! Please read these Terms and Conditions
          ("Terms") carefully before using our platform. By accessing or using
          DocuSynth AI, you agree to be bound by these Terms. If you do not
          agree with any part of these Terms, you must not use our services.
        </p>
      </div>
      <div className="pt-[30px]">
        <ol className="list-decimal  ml-[17px]">
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Acceptance of Terms
            </li>
            By using DocuSynth AI, you agree to comply with and be bound by
            these Terms, our Privacy Policy, and any additional terms and
            conditions that may apply to specific sections of the platform or to
            products and services available through the platform.
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Description of Services
            </li>
            DocuSynth AI provides an automated invoice processing system using
            cutting-edge AI and OCR technology. Our services include:
            <ul className="list-disc ml-[20px] pt-[10px]">
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300]">
                Automated data entry and invoice processing
              </li>
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] ">
                Management of complex layouts and handwriting
              </li>
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] ">
                Reduced processing time and human error
              </li>
            </ul>
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              User Responsibilities
            </li>
            As a user of DocuSynth AI, you agree to:
            <ul className="list-disc ml-[20px] pt-[10px]">
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300]">
                Provide accurate and complete information during the
                registration process
              </li>
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] ">
                Maintain the confidentiality of your account information
              </li>
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] ">
                Notify us immediately of any unauthorized use of your account
              </li>
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] ">
                Use the platform in compliance with all applicable laws and
                regulations
              </li>
            </ul>
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Subscription and Fees
            </li>
            Access to DocuSynth AI may require a subscription and payment of
            fees. By subscribing, you agree to:
            <ul className="list-disc ml-[20px] pt-[10px]">
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300]">
                Pay all applicable fees in a timely manner
              </li>
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] ">
                Allow us to charge your provided payment method for the
                subscription fees and any applicable taxes
              </li>
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] ">
                Understand that subscription fees are non-refundable, except as
                required by law
              </li>
            </ul>
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Intellectual Property
            </li>
            All content, features, and functionality on the DocuSynth AI
            platform, including but not limited to software, text, graphics,
            logos, and images, are the exclusive property of DocuSynth AI and
            are protected by intellectual property laws. You agree not to:
            <ul className="list-disc ml-[20px] pt-[10px]">
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300]">
                Reproduce, distribute, or create derivative works based on our
                content without our express written permission
              </li>
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] ">
                Use our trademarks, logos, or other proprietary information
                without our prior consent
              </li>
            </ul>
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Privacy Policy
            </li>
            Your use of DocuSynth AI is also governed by our Privacy Policy,
            which explains how we collect, use, and disclose information that
            pertains to your privacy. By using our platform, you consent to the
            practices described in our Privacy Policy.
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Data Security
            </li>
            We employ measures to ensure the security of your data. However, you
            acknowledge that no system is completely secure, and we cannot
            guarantee the absolute security of your data. You agree to:
            <ul className="list-disc ml-[20px] pt-[10px]">
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300]">
                Use DocuSynth AI at your own risk
              </li>
              <li className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] ">
                Follow our guidelines for maintaining the security of your
                account and data
              </li>
            </ul>
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Limitation of Liability
            </li>
            To the fullest extent permitted by law, DocuSynth AI shall not be
            liable for any indirect, incidental, special, or consequential
            damages arising out of or in connection with your use of the
            platform. This includes, but is not limited to, damages for loss of
            profits, goodwill, data, or other intangible losses.
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Indemnification
            </li>
            You agree to indemnify, defend, and hold harmless DocuSynth AI, its
            affiliates, and their respective officers, directors, employees, and
            agents from and against any claims, liabilities, damages, losses,
            and expenses arising out of or in any way connected with your use of
            the platform or your violation of these Terms.
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">Termination</li>
            We reserve the right to terminate or suspend your access to
            DocuSynth AI at any time, without notice, for conduct that we
            believe violates these Terms or is harmful to other users, us, or
            third parties, or for any other reason.
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Modifications to Terms
            </li>
            We may revise these Terms from time to time. The most current
            version will always be posted on our website. By continuing to use
            DocuSynth AI after changes become effective, you agree to be bound
            by the revised Terms.
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[30px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Governing Law
            </li>
            These Terms shall be governed by and construed in accordance with
            the laws of [Your Country/State], without regard to its conflict of
            law provisions. Any disputes arising from or relating to these Terms
            shall be resolved in the courts of [Your Country/State].
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] pb-[4px]">
            <li className="text-[#004862] font-[600] pb-[10px]">
              Contact Information
            </li>
            Information If you have any questions about these Terms, please
            contact us at: DocuSynth AI Support Team
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-light">
            <span className="text-[#004862] font-semibold pr-2">Email:</span>
            support@docusynthai.com
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-light">
            <span className="text-[#004862] font-semibold pr-2">Phone:</span>
            [(614)398-4897]
          </p>
          <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-light pb-[40px] xl:pb-[0px]">
            <span className="text-[#004862] font-semibold pr-2">Address:</span>
            3825 Edwards rd suite 103 Cincinnati oh 45209
          </p>
        </ol>
      </div>
    </div>
  );
};

export default TermsAndConditions;
