import React from "react";
import Header from "../utils/header";
import Footer from "../utils/footer";
import { Helmet } from "react-helmet";
import Blog4 from "../components/blog4";

const Blog4Page = () => {
  return (
    <div>
      <Helmet>
        <title>Docusynth - Blog4</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
      </Helmet>
      <Header />
      <Blog4 />
      <Footer display="none" />
    </div>
  );
};

export default Blog4Page;
