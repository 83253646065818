import React from 'react'
import ComingSoonImage from "../../assets/images/comingSoon/Coming Soon.png"
const ComingSoon = () => {
  return (
    <div className='w-full h-[100vh]'>
      <img src={ComingSoonImage} alt='ComingSoonImage' className='w-full h-full'/>
    </div>
  )
}

export default ComingSoon