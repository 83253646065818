import React from "react";
import Header from "../utils/header";
import TermsAndConditions from "../components/terms&Conditions";
import Footer from "../utils/footer";
import { Helmet } from "react-helmet"

const TermsAndConditionsPage = () => {
  return (
    <div>
    <Helmet>
        <title>Docusynth - TermsAndConditions</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
    </Helmet>
      <Header />
      <TermsAndConditions />
      <Footer />
    </div>
  );
};

export default TermsAndConditionsPage;
