import React from "react";
import PasswordUpdated from "../utils/passwordUpdated";

const PasswordUpdatedPage = () => {
  return (
    <div>
      <PasswordUpdated />
    </div>
  );
};

export default PasswordUpdatedPage;
