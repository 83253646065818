import React from "react";
import LoginLogo from "../assets/images/userDetails/Group.svg";
import { useNavigate } from "react-router-dom";
const DepositConfirmation = () => {
  const navigate = useNavigate();
  return (
    <div className="flex ">
      <div className="login-bg w-[45%]">
        <div className="h-[inherit] w-full flex justify-center items-center">
          <div>
            <div className="flex justify-center">
              <img src={LoginLogo} alt="LoginLogo" />
            </div>
            <div className="flex items-center justify-center pt-[50px]">
              <p className="text-white font-[400] text-[48.629px] cursor-pointer">
                DocuSynth Ai
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute right-5 top-5 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-square-rounded-x"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          stroke-width="0.5"
          stroke="#222222"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M10 10l4 4m0 -4l-4 4" />
          <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
        </svg>
      </div>

      <div className="w-[55%] flex flex-col items-center  justify-center ">
        <div className="pb-[20px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-circle-check"
            width="64"
            height="64"
            viewBox="0 0 24 24"
            stroke-width="0.5"
            stroke="#7bc62d"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
        </div>
        <div>
          <p className="text-[#222] text-[16px] font-normal w-[500px] text-center">
            Thank you for subscribing. Trail period has been active. Soon you
            will receive an email regarding credentials. Meanwhile you can visit{" "}
            <a
              href="https://docusynthai.com"
              target="_blank"
              style={{
                textDecoration: "underline",
                color: "#0070E0",
              }}
            >
              {"  "}
              Docusynth Ai
            </a>
            {"  "}
            for more details.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DepositConfirmation;
