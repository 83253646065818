import React from "react";
import ContactImage1 from "../../assets/images/contact/Group 5422.png";
import ContactImage2 from "../../assets/images/contact/Group 5423.png";
import ContactImage3 from "../../assets/images/contact/Group 5424.png";
import { Fade } from "react-reveal";
const Information = () => {
  return (
    <div className=" bg-[#004862] ">
      <div className="container-class">
        <div className=" flex items-center flex-wrap justify-center xl:justify-between pt-[80px]  xl:pt-[60px] pb-[80px]  xl:pb-[60px]">
          <Fade left>
            <div className="w-full md:w-[50%] xl:w-[30%] text-start flex justify-center xl:justify-start items-center gap-5">
              <div className="w-[30px] md:w-[40px] xl:w-[80px] h-[30px] md:h-[40px] xl:h-[80px]">
                <img
                  src={ContactImage1}
                  alt="ContactImage1"
                  className="w-full h-full"
                />
              </div>
              <div className="w-[50%] md:w-[48%] xl:w-[55%]">
                <h1 className="text-white text-[20px] md:text-[22px] xl:text-[24px] font-medium xl:font-semibold">
                  Address
                </h1>
                <p className="text-[14px] md:text-[16] xl:text-[18px] font-medium text-white pt-2.5">
                3825 Edwards rd suite 103 Cincinnati oh 45209
                </p>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="pt-[30px] md:pt-0 w-full md:w-[50%] xl:w-[40%] text-start flex justify-center xl:justify-start items-center gap-5">
              <div className="w-[30px] md:w-[40px] xl:w-[80px] h-[30px] md:h-[40px] xl:h-[80px]">
                <img
                  src={ContactImage2}
                  alt="ContactImage1"
                  className="w-full h-full"
                />
              </div>
              <div className="w-[50%] md:w-[21%] xl:w-[50%]">
                <h1 className="text-white text-[20px] md:text-[22px] xl:text-[24px] font-medium xl:font-semibold">
                  Email
                </h1>
                <p className="text-[14px] md:text-[16] xl:text-[18px] font-medium text-white pt-2.5">
                  sales@docusynthai.com
                </p>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className=" pt-[30px] xl:pt-0 w-full md:w-[50%] xl:w-[30%] text-start flex justify-center xl:justify-start items-center gap-5">
              <div className="w-[30px] md:w-[40px] xl:w-[80px] h-[30px] md:h-[40px] xl:h-[80px]">
                <img
                  src={ContactImage3}
                  alt="ContactImage1"
                  className="w-full h-full"
                />
              </div>
              <div className="w-[50%] md:w-[48%] xl:w-[50%]">
                <h1 className="text-white text-[20px] md:text-[22px] xl:text-[24px] font-medium xl:font-semibold">
                  Phone
                </h1>
                <p className="text-[14px] md:text-[16] xl:text-[18px] font-medium text-white pt-2.5">
                  +1 (646) 789-1854
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Information;
