import React, { useState } from "react";
import BlogsImage1 from "../../assets/images/blogs/Rectangle 5904.png";
import BlogsImage2 from "../../assets/images/blogs/Rectangle 5905.png";
import BlogsImage3 from "../../assets/images/blogs/Rectangle 5906 2.png";
import BlogsImage4 from "../../assets/images/blogs/Rectangle 5907 2.png";
import { Link } from "react-router-dom";
const Blogs = () => {
  const [hoverEffect1, setHoverEffect1] = useState(false);
  const [hoverEffect2, setHoverEffect2] = useState(false);
  const [hoverEffect3, setHoverEffect3] = useState(false);
  const [hoverEffect4, setHoverEffect4] = useState(false);
  return (
    <div className="container-class pt-[120px]">
      <div className="flex flex-col xl:flex-row items-center gap-[60px] flex-wrap">
        <div
          onMouseEnter={() => setHoverEffect1(true)}
          onMouseLeave={() => setHoverEffect1(false)}
          className="w-full xl:w-[30%] cursor-pointer relative"
        >
          {hoverEffect1 && (
            <div className="absolute bg-[rgba(0,0,0,0.7)] w-full h-[380px] rounded-[10px]">
              <div className="flex items-center justify-center h-[inherit]">
                <Link to={"/blog-1-inner"}>
                  <button
                    type="button"
                    className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[8px] px-[32px] font-[400] rounded-[6px] text-[16px]"
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          )}
          <div className="w-full h-[380px]">
            <img src={BlogsImage1} alt="BlogImage1" className="w-full h-full" />
          </div>
          <div className="pt-[30px] ">
            <p className="text-[#004862] text-[16px] md:text-[18px] xl:text-[20px] font-[600]">
              Revolutionizing Construction Invoicing with DocuSynth AI Software
            </p>
          </div>
        </div>
        <div
          onMouseEnter={() => setHoverEffect2(true)}
          onMouseLeave={() => setHoverEffect2(false)}
          className="w-full xl:w-[30%] cursor-pointer relative"
        >
          {hoverEffect2 && (
            <div className="absolute bg-[rgba(0,0,0,0.7)] w-full h-[380px] rounded-[10px]">
              <div className="flex items-center justify-center h-[inherit]">
                <Link to={"/blog-2-inner"}>
                  <button
                    type="button"
                    className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[8px] px-[32px] font-[400] rounded-[6px] text-[16px]"
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          )}
          <div className="w-full h-[380px]">
            <img src={BlogsImage2} alt="BlogImage1" className="w-full h-full" />
          </div>
          <div className="pt-[30px] ">
            <p className="text-[#004862] text-[16px] md:text-[18px] xl:text-[20px] font-[600]">
              AI vs. Traditional Invoicing: A Comparative Analysis for
              Construction Companies
            </p>
          </div>
        </div>
        <div
          onMouseEnter={() => setHoverEffect3(true)}
          onMouseLeave={() => setHoverEffect3(false)}
          className="w-full xl:w-[30%] cursor-pointer relative"
        >
          {hoverEffect3 && (
            <div className="absolute bg-[rgba(0,0,0,0.7)] w-full h-[380px] rounded-[10px]">
              <div className="flex items-center justify-center h-[inherit]">
                <Link to={"/blog-3-inner"}>
                  <button
                    type="button"
                    className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[8px] px-[32px] font-[400] rounded-[6px] text-[16px]"
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          )}
          <div className="w-full h-[380px]">
            <img src={BlogsImage3} alt="BlogImage1" className="w-full h-full" />
          </div>
          <div className="pt-[30px] ">
            <p className="text-[#004862] text-[16px] md:text-[18px] xl:text-[20px] font-[600]">
              Top Features to Look for in Invoicing Software for Construction
              Companies
            </p>
          </div>
        </div>
        <div
          onMouseEnter={() => setHoverEffect4(true)}
          onMouseLeave={() => setHoverEffect4(false)}
          className="w-full xl:w-[30%] cursor-pointer relative"
        >
          {hoverEffect4 && (
            <div className="absolute bg-[rgba(0,0,0,0.7)] w-full h-[380px] rounded-[10px]">
              <div className="flex items-center justify-center h-[inherit]">
                <Link to={"/blog-4-inner"}>
                  <button
                    type="button"
                    className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[8px] px-[32px] font-[400] rounded-[6px] text-[16px]"
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          )}
          <div className="w-full h-[380px]">
            <img src={BlogsImage4} alt="BlogImage1" className="w-full h-full" />
          </div>
          <div className="pt-[30px] ">
            <p className="text-[#004862] text-[16px] md:text-[18px] xl:text-[20px] font-[600]">
              Ways Contractors Can Save Money and Increase Productivity
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
