import React from "react";
import Header from "../utils/header";
import Banner from "../components/contact/banner";
import Information from "../components/contact/info";
import BookDemo from "../components/contact/bookDemo";
import Footer from "../utils/footer";
import { Helmet } from "react-helmet"

const Contact = () => {
  return (
    <div>
    <Helmet>
        <title>Docusynth - Contact</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
    </Helmet>
      <Header />
      <Banner />
       <Information />
      <BookDemo  />
      <Footer display="none" paddingTop="0px"/>
    </div>
  );
};

export default Contact;
