import React from "react";
import ProcedureImage from "../../assets/images/home/Group 5409.png";
import ProcedureImage1 from "../../assets/images/home/Group 5410.png";
import { Fade } from "react-reveal";
const Procedure = () => {
  return (
    <div className="procedure-bg w-full ">
      <div className="container-class py-[100px]">
        <div className="flex flex-col xl:flex-row xl:items-center justify-between items-start">
          <div className="w-full xl:w-[50%]">
            <Fade left>
              <img
                src={ProcedureImage}
                alt="ProcedureImage"
                className="w-full"
              />
            </Fade>
          </div>

          <div className=" w-full xl:w-[45%] flex flex-col pt-[30px] xl:pt-[0px] items-start xl:items-end">
            <Fade right>
              <h2 className="text-[#222] text-[24px] md:text-[30px] xl:text-[40px] font-medium w-full xl:w-[509px]">
                Leave paper invoices and payments in the dust
                <span className="text-[#00D588]">.</span>
              </h2>
              <p className="text-[#222] w-full xl:w-[510px] pt-[20px] text-[14px] md:text-[16px] xl:text-[18px] font-light">
                Our AP automation solutions are helping more businesses increase
                efficiency, visibility and control in the AP process than ever
                before. Seamlessly integrated with your current accounting
                system or ERP, and connected to one of the largest supplier
                networks, you’re able to process invoices and make payments
                without touching any paper.
              </p>
            </Fade>
          </div>
        </div>
        <div className="flex flex-col-reverse xl:flex-row xl:items-center justify-between items-start pt-[100px] xl:pt-[200px]">
          <div className=" w-full xl:w-[45%] flex flex-col pt-[30px] xl:pt-[0px] items-start">
            <Fade left>
              <h2 className="text-[#222] text-[24px] md:text-[30px] xl:text-[40px] font-medium w-full xl:w-[490px]">
                Pay bills with confidence & context
                <span className="text-[#00D588]">.</span>
              </h2>
              <p className="text-[#222] w-full xl:w-[510px] pt-[20px] text-[14px] md:text-[16px] xl:text-[18px] font-light">
                Our AI OCR Solution automates invoice data extraction, reduces
                errors, enhances processing efficiency, and facilitates storage
                and retrieval. It integrates with accounting systems to ensure
                accurate payment and cross-verification for accuracy. OCR allows
                cost tracking, budget monitoring and fraud detection. Faster
                processing and payments strengthen vendor relationships and
                maintain compliance.
              </p>
            </Fade>
          </div>
          <div className="w-full xl:w-[50%]">
            <Fade right>
              <img
                src={ProcedureImage1}
                alt="ProcedureImage"
                className="w-full"
              />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Procedure;
