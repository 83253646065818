import { Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Scroll from "../components/scroll/scroll";
const Header = () => {
  const [activeText, setActiveText] = useState("Home");
  // const [hamburgerSelect, setHamburgerSelect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleMouseEnter = (text) => {
    setActiveText(text);
  };

  const handleClick = (text) => {
    setActiveText(text);
  };

  useEffect(() => {
    // You can perform any actions here when activeText changes
  }, [activeText]);

  return (
    <div className="bg-[#222] py-[28px]">
      <div className="container-class  ">
        <div className="hidden xl:flex items-center justify-between">
          <div onClick={() => navigate("/")} className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="46"
              viewBox="0 0 45 46"
              fill="none"
            >
              <path
                d="M44.5017 22.9986C44.5017 29.142 42.0107 34.706 37.9858 38.7336C33.9583 42.7585 28.3942 45.2495 22.2509 45.2495H16.0341C15.9389 45.1978 12.8659 43.4873 8.03337 29.966C5.36283 22.4955 0 0.750488 0 0.750488H22.2509C34.5402 0.750488 44.5017 10.712 44.5017 22.9986Z"
                fill="#004862"
              />
              <path
                d="M14.2583 33.2471C10.8073 38.3054 15.9145 45.0905 16.0341 45.2483C15.9389 45.1993 12.8659 43.4887 8.03338 29.9674C5.36284 22.497 0 0.751953 0 0.751953L26.0038 28.7627C26.0038 28.7627 18.1744 27.5063 14.2583 33.2471Z"
                fill="#00D588"
              />
            </svg>
          </div>
          <div className="flex items-center gap-[50px]">
            <p
              className={`text-white text-[18px] font-normal capitalize cursor-pointer ${
                activeText === "Home" ? "hover:text-[#00D588]" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("Home")}
              onClick={() => {
                handleClick("Home");
                navigate("/");
              }}
            >
              Home
            </p>
            <p
              className={`text-white text-[18px] font-normal capitalize cursor-pointer ${
                activeText === "About" ? "hover:text-[#00D588]" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("About")}
              onClick={() => {
                handleClick("About");
                navigate("/about");
              }}
            >
              About
            </p>
            <p
              className={`text-white text-[18px] font-normal capitalize  cursor-pointer ${
                activeText === "Solution" ? "hover:text-[#00D588]" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("Solution")}
              onClick={() => {
                handleClick("Solution");
                navigate("/solution");
              }}
            >
              Solution
            </p>
            <p
              className={`text-white text-[18px] font-normal capitalize cursor-pointer ${
                activeText === "Contact" ? "hover:text-[#00D588]" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("Contact")}
              onClick={() => {
                handleClick("Contact");
                navigate("/contact");
              }}
            >
              Contact
            </p>
          </div>
          <div className="flex items-center gap-3">
            <div>
              <a
                href="https://dashboard.docusynthai.com/log-in"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  type="button"
                  className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[8px] px-[32px] font-[400] rounded-[6px] text-[16px]"
                >
                  Login
                </button>
              </a>
            </div>
            <div>
              <Scroll targetId="pricing" marginTop={0}>
                <div onClick={() => navigate("/")}>
                  <button
                    type="button"
                    className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[8px] px-[32px] font-[400] rounded-[6px] text-[16px]"
                  >
                    Get Started
                  </button>
                </div>
              </Scroll>
            </div>
          </div>
        </div>
        <div className="xl:hidden flex items-center justify-between ">
          <div onClick={() => navigate("/")} className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="46"
              viewBox="0 0 45 46"
              fill="none"
            >
              <path
                d="M44.5017 22.9986C44.5017 29.142 42.0107 34.706 37.9858 38.7336C33.9583 42.7585 28.3942 45.2495 22.2509 45.2495H16.0341C15.9389 45.1978 12.8659 43.4873 8.03337 29.966C5.36283 22.4955 0 0.750488 0 0.750488H22.2509C34.5402 0.750488 44.5017 10.712 44.5017 22.9986Z"
                fill="#004862"
              />
              <path
                d="M14.2583 33.2471C10.8073 38.3054 15.9145 45.0905 16.0341 45.2483C15.9389 45.1993 12.8659 43.4887 8.03338 29.9674C5.36284 22.497 0 0.751953 0 0.751953L26.0038 28.7627C26.0038 28.7627 18.1744 27.5063 14.2583 33.2471Z"
                fill="#00D588"
              />
            </svg>
          </div>
          <div className="z-[300]">
            {/* Button to open the drawer */}
            <button onClick={() => setIsOpen(!isOpen)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-menu"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 8l16 0" />
                <path d="M4 16l16 0" />
              </svg>
            </button>

            {/* Drawer */}
            <Transition
              show={isOpen}
              enter="transition ease-out duration-300 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease-in duration-300 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex justify-end min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  {/* Background overlay */}
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div
                      className="fixed inset-0 transition-opacity"
                      aria-hidden="true"
                      onClick={() => setIsOpen(false)}
                    >
                      <div className="absolute inset-0 bg-[rgba(0,0,0,0.8)] opacity-75"></div>
                    </div>
                  </Transition.Child>

                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300 transform"
                    enterFrom="opacity-0 translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="ease-in duration-300 transform"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="opacity-0 translate-x-full"
                  >
                    <div className="flex justify-end w-full absolute inset-0">
                      <div className="overflow-y-scroll inline-block z-50 align-top bg-white w-[50%] h-[100vh] text-left overflow-hidden shadow-xl transform transition-all ">
                        <div className="py-[30px] px-[20px] xl:px-[30px]">
                          <button
                            onClick={() => setIsOpen(false)}
                            className="absolute top-[34px] right-[20px] xl:right-[30px]  text-gray-500 hover:text-gray-700"
                          >
                            <svg
                              className="h-8 w-8"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                          <div className="cursor-pointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="45"
                              height="46"
                              viewBox="0 0 45 46"
                              fill="none"
                            >
                              <path
                                d="M44.5017 22.9986C44.5017 29.142 42.0107 34.706 37.9858 38.7336C33.9583 42.7585 28.3942 45.2495 22.2509 45.2495H16.0341C15.9389 45.1978 12.8659 43.4873 8.03337 29.966C5.36283 22.4955 0 0.750488 0 0.750488H22.2509C34.5402 0.750488 44.5017 10.712 44.5017 22.9986Z"
                                fill="#004862"
                              />
                              <path
                                d="M14.2583 33.2471C10.8073 38.3054 15.9145 45.0905 16.0341 45.2483C15.9389 45.1993 12.8659 43.4887 8.03338 29.9674C5.36284 22.497 0 0.751953 0 0.751953L26.0038 28.7627C26.0038 28.7627 18.1744 27.5063 14.2583 33.2471Z"
                                fill="#00D588"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="flex flex-col  items-start gap-[20px] w-[fit-content] px-[30px]">
                          <p
                            className={`text-black text-[18px] font-normal capitalize cursor-pointer  text-start ${
                              activeText === "Home"
                                ? "hover:text-[#00D588]"
                                : ""
                            }`}
                            onMouseEnter={() => handleMouseEnter("Home")}
                            onClick={() => {
                              handleClick("Home");
                              navigate("/");
                            }}
                          >
                            Home
                          </p>
                          <p
                            className={`text-black text-[18px] font-normal capitalize cursor-pointer text-start ${
                              activeText === "About"
                                ? "hover:text-[#00D588]"
                                : ""
                            }`}
                            onMouseEnter={() => handleMouseEnter("About")}
                            onClick={() => {
                              handleClick("About");
                              navigate("/about");
                            }}
                          >
                            About
                          </p>
                          <p
                            className={`text-black text-[18px] font-normal capitalize  cursor-pointer  text-start ${
                              activeText === "Solution"
                                ? "hover:text-[#00D588]"
                                : ""
                            }`}
                            onMouseEnter={() => handleMouseEnter("Solution")}
                            onClick={() => {
                              handleClick("Solution");
                              navigate("/solution");
                            }}
                          >
                            Solution
                          </p>
                          <p
                            className={`text-black text-[18px] font-normal capitalize cursor-pointer text-start ${
                              activeText === "Contact"
                                ? "hover:text-[#00D588]"
                                : ""
                            }`}
                            onMouseEnter={() => handleMouseEnter("Contact")}
                            onClick={() => {
                              handleClick("Contact");
                              navigate("/contact");
                            }}
                          >
                            Contact
                          </p>
                        </div>
                        <div className="flex flex-col pt-[20px] px-[30px] gap-3">
                          <div>
                            <a
                              href="https://dashboard.docusynthai.com/log-in"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <button
                                type="button"
                                className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[8px] px-[42px] font-[400] rounded-[6px] text-[12px]"
                              >
                                Login
                              </button>
                            </a>
                          </div>
                          <div>
                            <Scroll targetId="pricing" marginTop={0}>
                              <div onClick={() => navigate("/")}>
                                <button
                                  type="button"
                                  className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[8px] px-[24px] font-[400] rounded-[6px] text-[12px]"
                                >
                                  Get Started
                                </button>
                              </div>
                            </Scroll>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
