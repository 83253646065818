import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SignUpPage from "../container/SignUpPage";
import LoginPage from "../container/LoginPage";
import HomePage from "../container/HomePage";
import About from "../container/About";
import Solution from "../container/Solution";
import Contact from "../container/Contact";
import Pricing from "../container/Pricing";
import BlogsPage from "../container/BlogsPage";
import Blog1 from "../container/Blog1Page";
import Blog2 from "../container/Blog2Page";
import Blog3Page from "../container/Blog3Page";
import Blog4Page from "../container/Blog4Page";
import TermsAndConditionsPage from "../container/Terms&ConditionsPage";
import PrivacyPolicy from "../container/PrivacyPolicy";
import ComingSoonPage from "../container/comingSoonPage";
import ForgotPasswordPage from "../container/ForgotPasswordPage";
import ResetPasswordPage from "../container/ResetPasswordPage";
import PasswordUpdatedPage from "../container/PasswordUpdatedPage";
import DepositConfirmationPage from "../container/DepositConfirmationPage";

const RoutesPages = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: location.pathname,
        page_title: document.title,
      });
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/solution" element={<Solution />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/blogs" element={<BlogsPage />} />
      <Route path="/blog-1-inner" element={<Blog1 />} />
      <Route path="/blog-2-inner" element={<Blog2 />} />
      <Route path="/blog-3-inner" element={<Blog3Page />} />
      <Route path="/blog-4-inner" element={<Blog4Page />} />
      <Route path="/terms-service" element={<TermsAndConditionsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/coming-soon" element={<ComingSoonPage />} />
      <Route path="/log-in" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route
        path="/successfully-reset-password"
        element={<PasswordUpdatedPage />}
      />
      <Route
        path="/deposit-confirmation"
        element={<DepositConfirmationPage />}
      />
      <Route path="/sign-up" element={<SignUpPage />} />
    </Routes>
  );
};

export default RoutesPages;
