import axios from "axios";
import React, { useState, useEffect } from "react";
import { Fade } from "react-reveal";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import Countries from "../../utils/countries";
import industries from "../../utils/industries.json";
import Logo from "../../assets/images/home/Logo.svg";
// import CheckoutForm from "./CheckoutForm";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  // getLatLng,
} from "react-places-autocomplete";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Banner = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const plan = urlParams.get("plan");
  const period = urlParams.get("period");
  console.log("period", period);
  // const options = {
  //   // passing the client secret obtained from the server
  // };
  // const [clientSecret, setClientSecret] = useState("");
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [products, setProducts] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pricing, setpricing] = useState({
    firstName: "",
    lastName: "",
    plan: plan || "",
    type: "",
    country: "",
    phone: "",
    email: "",
    businessName: "",
    address: "",
    state: "",
    city: "",
    zipCode: "",
    website: "",
    insdustry: "",
    businessType: "",
    subscriptionType: plan === "Enterprise" ? "Enterprise" : "",
    period: period || "",
    amount: plan === "starter" ? 99 : 199,
    accountingSoftware: "",
    otherSoftwareName: "",
  });
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  console.log("formErrors", formErrors);
  const requiredFields = [
    "firstName",
    "lastName",
    "plan",
    "type",
    "country",
    "phone",
    "email",
    "businessName",
    "address",
    "state",
    "city",
    "zipCode",
    "website",
    "insdustry",
    "businessType",
    "subscriptionType",
  ];
  const notify = () => toast("Error");
  useEffect(() => {
    getProducts();
  }, []);
  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const selectedResult = results[0];
      // Extracting city, zipcode, and state from the address components
      const cityComponent = selectedResult.address_components.find(
        (component) => component.types.includes("locality")
      );
      const zipcodeComponent = selectedResult.address_components.find(
        (component) => component.types.includes("postal_code")
      );
      const stateComponent = selectedResult.address_components.find(
        (component) => component.types.includes("administrative_area_level_1")
      );
      setCity(cityComponent ? cityComponent.long_name : "");
      setZipcode(zipcodeComponent ? zipcodeComponent.long_name : "");
      setState(stateComponent ? stateComponent.long_name : "");
    } catch (error) {
      console.error("Error retrieving geolocation:", error);
    }
  };
  const getProducts = () => {
    axios
      .get("https://documentai-dev.akru.co/api/stripe/products", {})
      .then(function (response) {
        console.log("response api getProducts", response.data.body);
        setProducts(response.data.body);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };

  const validateForm = () => {
    const errors = {};
    requiredFields.forEach((fieldName) => {
      const formattedFieldName = fieldName.replace("_", " ");

      if (!pricing[fieldName] || !pricing[fieldName].trim()) {
        errors[fieldName] = `${
          formattedFieldName.charAt(0).toUpperCase() +
          formattedFieldName.slice(1)
        } is required`;
      }
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (pricing.email && !emailRegex.test(pricing.email)) {
      errors.email = "Enter a valid email address";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormChanged = (name, value) => {
    setpricing({
      ...pricing,
      plan: plan,
      address: address,
      city: city,
      state: state,
      zipCode: zipcode,
      [name]: value,
    });
    if (name === "accountingSoftware" && value === "others") {
      setShowOtherInput(true);
    } else if (name === "accountingSoftware") {
      setShowOtherInput(false);
    }
  };
  const handleActiveChanged = (businessType) => {
    setpricing({
      ...pricing,
      businessType: businessType,
    });
  };

  const submitForm = async (e) => {
    console.log("submitForm", e);
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      setIsLoading(true);
      let selectedUrl = "";
      console.log("Pricing:", pricing);

      console.log("selectedUrl", selectedUrl);

      if (pricing.period === "Monthly") {
        if (pricing.subscriptionType === "Starter") {
          selectedUrl = "https://buy.stripe.com/00gaH09m8eeQ30QfYY";
          // selectedUrl = "https://buy.stripe.com/test_00gcNi4m45P3fQY9AA";
        } else if (pricing.subscriptionType === "Premiam") {
          selectedUrl = "https://buy.stripe.com/dR602meGsdaM0SI6oq";
        }
      } else if (pricing.period === "Yearly") {
        if (pricing.subscriptionType === "Starter") {
          selectedUrl = "https://buy.stripe.com/dR62au8i42w88la4gk";
          // selectedUrl = "https://buy.stripe.com/8wMaH0gOAgmYati4gh";
        } else if (pricing.subscriptionType === "Premiam") {
          selectedUrl = "https://buy.stripe.com/28og1k2XKfiU0SI5kp";
          // selectedUrl = "https://buy.stripe.com/00g3ey69W7Qs44U147";
        }
      }

      console.log("selectedUrl", selectedUrl);
      axios
        .post("https://documentai-dev.akru.co/api/user/contactUs", {
          ...pricing,
          subscriptionUrl: selectedUrl,
        })
        .then(async function (response) {
          // Declare this function as async
          console.log("submitForm response", response);
          console.log("products", products, plan);
          if (plan === "Enterprise") {
            setShowModal(true);
          } else {
            window.location.href = selectedUrl;
          }
          setpricing({
            firstName: "",
            lastName: "",
            plan: plan || "",
            type: "",
            country: "",
            phone: "",
            email: "",
            businessName: "",
            address: "",
            state: "",
            city: "",
            zipCode: "",
            website: "",
            insdustry: "",
            businessType: "",
            subscriptionType: "",
            accountingSoftware: "",
            otherSoftwareName: "",
          });
          // const packageSelected = products.find((result) => result.name == plan);
          // console.log('package', packageSelected);
          // await stripePromise.redirectToCheckout({ sessionId: response.data.body.id });
          // setClientSecret(response.data.body.clientSecret);
          // setClientSecret(packageSelected);
        })
        .catch(function (error) {
          console.error("Error in API request:", error);

          if (error.response && error.response.status === 400) {
            toast.error(error.message, {
              position:"top-right",
            });
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Error setting up the request:", error.message);
          }

          setIsLoading(false);
        });
    }
    //     .catch(function (error) {
    //       console.error("Error in api", error);
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //     });
    // }
    else {
      const emptyFields = requiredFields.filter(
        (fieldName) => !pricing[fieldName] || !pricing[fieldName].trim()
      );
      const fieldErrors = {};
      emptyFields.forEach((fieldName) => {
        fieldErrors[fieldName] = true;
      });
      setFormErrors(fieldErrors);
    }
  };

  return (
    <div className="container-class pt-[80px] ">
      <div className="flex flex-col items-center ">
        <Fade left>
          <div>
            <h1 className="text-center text-[#222] text-[30px] md:text-[36px] xl:text-[40px] font-medium">
              Try DocuSynth today
              <span className="text-[#00D588]">.</span>
            </h1>
            <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] w-full xl:w-[496px] text-center">
              Is bill line item data entry overwhelming? Eliminate the need for
              any manual data entry with
            </p>
          </div>
        </Fade>
        <Fade delay={400}>
          <form
            onSubmit={submitForm}
            className="bg-[#0048620d]  mt-[60px] md:mt-[80px] xl:mt-[100px] rounded-[10px] w-full p-[30px] xl:p-[50px] flex  flex-wrap justify-between gap-[20px] xl:gap-[30px]"
          >
            <div className="w-full xl:w-[48%]">
              <input
                value={pricing.firstName}
                onChange={(e) => handleFormChanged("firstName", e.target.value)}
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="First Name"
                type="text"
                required
                name="firstName"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              <input
                value={pricing.lastName}
                onChange={(e) => handleFormChanged("lastName", e.target.value)}
                required
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="Last Name"
                type="text"
                name="lastName"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              <input
                value={pricing.phone}
                onChange={(e) => handleFormChanged("phone", e.target.value)}
                required
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="Phone Number"
                type="number"
                name="phone"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              <input
                value={pricing.email}
                onChange={(e) => handleFormChanged("email", e.target.value)}
                required
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="Email"
                type="email"
                name="email"
              />
            </div>
            {["Starter", "Premiam"].includes(pricing.plan) ? (
              <>
                {pricing.period === "Monthly" ? (
                  <div className="w-full xl:w-[48%]">
                    <select
                      value={pricing.subscriptionType}
                      onChange={(e) =>
                        handleFormChanged("subscriptionType", e.target.value)
                      }
                      name="subscriptionType"
                      required
                      className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] text-[#666] text-[14px] font-[500] outline-[#EEE] appearance-none down-arrow"
                    >
                      <option value="">Select Plan</option>
                      <option value="Starter">Starter - $99/Month</option>
                      <option value="Premiam">Premium - $199/Month</option>
                    </select>
                  </div>
                ) : (
                  <div className="w-full xl:w-[48%]">
                    <select
                      value={pricing.subscriptionType}
                      onChange={(e) =>
                        handleFormChanged("subscriptionType", e.target.value)
                      }
                      name="subscriptionType"
                      required
                      className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] text-[#666] text-[14px] font-[500] outline-[#EEE] appearance-none down-arrow"
                    >
                      <option value="">Select Plan</option>
                      <option value="Starter">Starter - $891/Yearly</option>
                      <option value="Premiam">Premium - $1791/Yearly</option>
                    </select>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            <div className="w-full xl:w-[48%]">
              <select
                name="insdustry"
                value={pricing.insdustry}
                onChange={(e) => handleFormChanged("insdustry", e.target.value)}
                required
                className=" w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] text-[#666] text-[14px] font-[500] outline-[#EEE] appearance-none down-arrow"
              >
                <option value="">Industry</option>
                {industries.map((option) => {
                  return (
                    <option key={option.id} value={option.name}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w-full xl:w-[48%]">
              <select
                name="country"
                value={pricing.country}
                onChange={(e) => handleFormChanged("country", e.target.value)}
                required
                className=" w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] text-[#666] text-[14px] font-[500] outline-[#EEE] appearance-none down-arrow"
              >
                <option value="">Country</option>
                {Countries.map((option) => {
                  return (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w-full ">
              <p className="text-[#222] text-[20px] font-medium">
                I’m with a<span className="text-[#00D588]">:</span>
              </p>
              <div className="flex flex-col xl:flex-row items-start xl:items-center gap-[30px] pt-[20px] ">
                <div className="w-full xl:w-[24%]">
                  <div
                    onClick={() => handleActiveChanged("Business")}
                    className={`cursor-pointer py-[8px] px-[32px] font-[400] rounded-[6px] text-[16px] w-full text-center ${
                      pricing.businessType === "Business"
                        ? "text-[#00D588] bg-[#004862]"
                        : "text-[#004862] bg-[#0048620d] hover:text-[#00D588] hover:bg-[#004862]"
                    }`}
                  >
                    Business
                  </div>
                </div>
                <div className="w-full xl:w-[24%]">
                  <div
                    onClick={() => handleActiveChanged("Accounting Firm")}
                    className={`cursor-pointer py-[8px] px-[32px] font-[400] rounded-[6px] text-[16px] w-full text-center ${
                      pricing.businessType === "Accounting Firm"
                        ? "text-[#00D588] bg-[#004862]"
                        : "text-[#004862] bg-[#0048620d] hover:text-[#00D588] hover:bg-[#004862]"
                    }`}
                  >
                    Accounting Firm
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-[48%]">
              <input
                name="businessName"
                value={pricing.businessName}
                onChange={(e) =>
                  handleFormChanged("businessName", e.target.value)
                }
                required
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder={
                  pricing.businessType === "Accounting Firm"
                    ? "Firm Name"
                    : "Business Name"
                }
                type="text"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              <input
                name="website"
                value={pricing.website}
                onChange={(e) => handleFormChanged("website", e.target.value)}
                required
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="Website"
                type="text"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              {/* <input
                name="address"
                value={pricing.address}
                onChange={(e) => handleFormChanged("address", e.target.value)}
                required
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder={
                  pricing.businessType === "Accounting Firm"
                    ? "Firm Address"
                    : "Business Address"
                }
                type="text"
              /> */}
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      required
                      className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                      {...getInputProps({ placeholder: "Enter Address" })}
                      placeholder={
                        pricing.businessType === "Accounting Firm"
                          ? "Firm Address"
                          : "Business Address"
                      }
                    />
                    <div>
                      <div className="absolute  w-fit">
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#eee"
                              : "#F9F9F9",
                            zIndex: 90,
                            border: "1px solid #eee",
                          };
                          return (
                            <div
                              className=" text-[#666]   p-4"
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div className="w-full xl:w-[48%]">
              <input
                name="type"
                value={pricing.type}
                onChange={(e) => handleFormChanged("type", e.target.value)}
                required
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="Suite/Unit"
                type="text"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              {/* <input
                name="city"
                value={pricing.city}
                onChange={(e) => handleFormChanged("city", e.target.value)}
                required
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="City"
                type="text"
              /> */}
              <input
                required
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                value={city}
                placeholder="City"
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="w-full xl:w-[48%]">
              {/* <input
                name="state"
                value={pricing.state}
                onChange={(e) => handleFormChanged("state", e.target.value)}
                required
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="State"
                type="text"
              /> */}
              <input
                required
                value={state}
                placeholder="State"
                onChange={(e) => setState(e.target.value)}
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              {/* <input
                name="zipCode"
                value={pricing.zipCode}
                onChange={(e) => handleFormChanged("zipCode", e.target.value)}
                required
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="Zip Code"
                type="number"
              /> */}
              <input
                required
                value={zipcode}
                placeholder="Zip Code"
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                onChange={(e) => setZipcode(e.target.value)}
              />
            </div>
            <>
              {/* <div>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({ placeholder: "Enter Address" })}
                    />
                    <div>
                      {loading ? <div>Loading...</div> : null}

                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#41b6e6"
                            : "#fff",
                        };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {/* <input value={city} onChange={(e) => setCity(e.target.value)} /> */}
              {/* <input
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
              /> */}
              {/* <input value={state} onChange={(e) => setState(e.target.value)} /> */}
              {/* </div> */}
            </>
            <div className="w-full xl:w-[48%]">
              <select
                id="softwareSelect"
                required
                onChange={(e) =>
                  handleFormChanged("accountingSoftware", e.target.value)
                }
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] text-[#666] text-[14px] font-[500] outline-[#EEE] appearance-none down-arrow"
                value={pricing.accountingSoftware}
              >
                <option value="">Accounting Software</option>
                <option value="quickbooksOnline">QuickBooks Online</option>
                <option value="quickbooksDesktop">QuickBooks Desktop</option>
                <option value="bothOnline&Desktop">
                  Both Online & Desktop
                </option>
                <option value="others">Others</option>
              </select>
            </div>
            {showOtherInput && (
              <div className="w-full xl:w-[48%]">
                <input
                  required
                  name="otherSoftwareName"
                  onChange={(e) =>
                    handleFormChanged("otherSoftwareName", e.target.value)
                  }
                  type="text"
                  placeholder="Others eg:Sage/NetSuite/Xero..."
                  id="otherSoftware"
                  className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                />
              </div>
            )}
            {/* <div className="w-full xl:w-[48%]">
              <select
                id="softwareSelect"
                required
                onChange={(e) =>
                  handleFormChanged("accountingSoftware", e.target.value)
                }
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] text-[#666] text-[14px] font-[500] outline-[#EEE] appearance-none down-arrow"
                value={pricing.accountingSoftware}
              >
                <option value="">Accounting Software</option>
                <option value="quickbooksOnline">QuickBooks Online</option>
                <option value="quickbooksDesktop">QuickBooks Desktop</option>
                <option value="bothOnline&Desktop">
                  Both Online & Desktop
                </option>
                <option value="others">Others</option>
              </select>
            </div>
            {showOtherInput && (
              <div className="w-full xl:w-[48%]">
                <input
                  required
                  name="otherSoftwareName"
                  onChange={handleFormChanged}
                  type="text"
                  placeholder="Others eg:Sage/NetSuite/Xero..."
                  id="otherSoftware"
                  className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                />
              </div>
            )} */}
            <div className="flex justify-end w-full pt-[20px]">
              <button
                type="submit"
                className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[6px] xl:py-[8px] px-[32px] font-[400] rounded-[6px] text-[12px] md:text-[14px] xl:text-[16px]"
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                  </svg>
                ) : (
                  <span>Proceed to Payment</span>
                )}
              </button>
            </div>
          </form>
        </Fade>
      </div>

      {showModal && (
        <div className="bg-[#00000080] w-full h-full fixed inset-0 flex justify-center items-center">
          <div className="bg-white w-[50%] rounded-[10px] p-[20px]">
            <div
              onClick={() => {
                setShowModal(false);
                navigate("/");
              }}
              className="cursor-pointer flex justify-end"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-square-rounded-x"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#222222"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 10l4 4m0 -4l-4 4" />
                <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
              </svg>
            </div>
            <div className="flex justify-center py-[20px]">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="pb-[20px]">
              <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] w-full  text-center">
                Great news! We've received your request for the Docusynth AI
                Enterprise package. A representative will be in touch shortly to
                discuss your needs. In the meantime, you can learn more about
                the Enterprise package on the Docusynth AI website. If you have
                any questions before then, feel free to contact us directly at{" "}
                <a
                  href="mailto:info@docusynthai.com"
                  className="text-[#0000FF] underline"
                >
                  info@docusynthai.com
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
      {/* {clientSecret && (
        <Elements stripe={stripePromise}>
          <CheckoutForm plan={plan} clientSecret={clientSecret} email={pricing.email}/>
        </Elements>
      )} */}
    </div>
  );
};

export default Banner;
