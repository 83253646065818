import React from "react";
import { Fade } from "react-reveal";

const AccountPayable = () => {
  return (
    <div className="pt-[270px] sm:pt-[390px]  md:pt-[380px] lg:pt-[520px] xl:pt-[500px]">
      <div className="accountPayable-bg">
        <div className="container-class pt-[200px] pb-[100px]">
          <div className="flex flex-col items-center">
            <Fade bottom>
              <h4 className="text-white text-[34px] xl:text-[40px] font-medium  w-[fit-content] xl:w-[481px] text-center text-[Nohemi]">
                Improving Every Aspect of Accounts Payable
                <span className="text-[#00D588]">.</span>
              </h4>
              <p className="text-white text-[14px] xl:text-[18px] font-[300] w-[fit-content] xl:w-[496px] text-center pt-[20px]">
                Is bill line item data entry overwhelming? <br /> Eliminate the
                need for any manual data entry with
              </p>
            </Fade>
          </div>
          <div className="pt-[92px] ">
            <div className="  w-full  flex flex-wrap items-start relative ">
              <Fade top>
                <div className="mt-[40px] md:mt-[0px] border-r-[#CCC] border-r  md:w-[50%] xl:w-[25%]  bg-white rounded-[10px] md:rounded-[0px]  md:rounded-l-[10px]">
                  <div className="pt-[60px] pb-[34px] px-[30px] min-h-[300px]">
                    <div className="bg-[#004862] rounded-[10px] w-[54px] h-[54px]  absolute top-[-28px] md:top-[-20px] flex justify-center items-center ">
                      <h5 className="text-[#00D588] font-[600] text-[22px] ">
                        <span className="text-white font-[600] text-[22px] pr-[2px]">
                          0
                        </span>
                        1
                      </h5>
                    </div>
                    <div>
                      <h1 className="text-[#222] font-[600] text-[18px] ">
                        Instant bill data capture
                      </h1>
                    </div>
                    <div className="pt-[20px]   leading-[120%] md:leading-[140%]">
                      <p className="text-[#222] font-[200] text-[14px] ">
                        DocuSynth lets you schedule payments ahead of time,
                        based on the due date and net terms we capture from the
                        bill. Schedule and authorize payments, then let us take
                        care of the rest.
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade bottom>
                <div className="mt-[40px] md:mt-[0px] border-r-[#CCC] border-r  md:w-[50%] xl:w-[25%]  bg-white rounded-[10px] md:rounded-[0px]  md:rounded-r-[10px] xl:rounded-r-[0px] ">
                  <div className="pt-[60px] pb-[34px] px-[30px] min-h-[300px]">
                    <div className="bg-[#004862] rounded-[10px] w-[54px] h-[54px]  absolute top-[-28px] md:top-[-20px] flex justify-center items-center ">
                      <h5 className="text-[#00D588] font-[600] text-[22px] ">
                        <span className="text-white font-[600] text-[22px] pr-[2px]">
                          0
                        </span>
                        2
                      </h5>
                    </div>
                    <div>
                      <h1 className="text-[#222] font-[600] text-[18px] ">
                        Approval & authorization
                      </h1>
                    </div>
                    <div className="pt-[20px]   leading-[120%] md:leading-[140%]">
                      <p className="text-[#222] font-[200] text-[14px] ">
                        Pay with confidence. Add one or multiple people to your
                        authorization flow. Trigger authorizers by dollar
                        threshold, vendor, and much more.
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
               <Fade top>
                <div className="mt-[40px] md:mt-[40px] xl:mt-[0px] border-r-[#CCC] border-r md:w-[50%] xl:w-[25%] rounded-[10px] md:rounded-[0px]  bg-white md:rounded-l-[10px] xl:rounded-l-[0px]  ">
                  <div className="pt-[60px] pb-[34px] px-[30px] min-h-[300px] ">
                    <div className="bg-[#004862] rounded-[10px] w-[54px] h-[54px]  absolute top-[-28px] md:top-[-20px] xl:top-[-20px] flex justify-center items-center ">
                      <h5 className="text-[#00D588] font-[600] text-[22px] ">
                        <span className="text-white font-[600] text-[22px] pr-[2px]">
                          0
                        </span>
                        3
                      </h5>
                    </div>
                    <div>
                      <h1 className="text-[#222] font-[600] text-[18px] ">
                        Mapping to QuickBooks
                      </h1>
                    </div>
                    <div className="pt-[20px]   leading-[120%] md:leading-[140%]">
                      <p className="text-[#222] font-[200] text-[14px] ">
                        DocuSynth auto-creates records in QuickBooks, even
                        coding them to the right place in the Chart of Accounts
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade bottom>
                <div className="mt-[40px] md:mt-[40px] xl:mt-[0px]  md:w-[50%] xl:w-[25%] rounded-[10px] md:rounded-[0px] bg-white md:rounded-r-[10px] ">
                  <div className="pt-[60px] pb-[34px] px-[30px] min-h-[300px] ">
                    <div className="bg-[#004862] rounded-[10px] w-[54px] h-[54px]  absolute top-[-28px] md:top-[-20px] xl:top-[-20px] flex justify-center items-center ">
                      <h5 className="text-[#00D588] font-[600] text-[22px] ">
                        <span className="text-white font-[600] text-[22px] pr-[2px]">
                          0
                        </span>
                        4
                      </h5>
                    </div>
                    <div>
                      <h1 className="text-[#222] font-[600] text-[18px] ">
                        Embedded payments
                      </h1>
                    </div>
                    <div className="pt-[20px]  leading-[120%] md:leading-[140%]">
                      <p className="text-[#222] font-[200] text-[14px] ">
                        Payments with full accounting context and authorization
                        controls. Discover flexible payment record creation and
                        streamlined reconciliation.
                      </p>
                    </div>
                  </div>
                </div>
              </Fade> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPayable;
