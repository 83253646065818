import React from "react";
import Header from "../utils/header";
import Banner from "../components/about/banner";
import AboutMarket from "../components/about/aboutMarket";
import Improving from "../components/about/improving";
import IndustryInsider from "../components/about/industryInsider";
import { Helmet } from "react-helmet"
import Footer from "../utils/footer";

const About = () => {
  return (
    <div>
    <Helmet>
        <title>Docusynth - About Us</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
    </Helmet>
      <Header />
      <Banner />
      <AboutMarket />
      <Improving />
      <IndustryInsider />
      <Footer />
    </div>
  );
};

export default About;
