import React, { useState } from "react";
import { Fade } from "react-reveal";

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const accordionData = [
    {
      title: "What is DocuSynth AI?",
      content1:
        "DocuSynth AI is an advanced document processing solution that leverages AI and OCR  technology to automate and streamline the handling of financial documents, ensuring accuracy, security, and efficiency.",
    },

    {
      title: "What problem does DocuSynth AI solve?",
      content1:
        "DocuSynth AI addresses the inefficiencies and errors associated with manual document processing. By automating invoice processing, it reduces operational costs and improves accuracy, helping businesses overcome the limitations of legacy technologies.",
    },

    {
      title: "How does DocuSynth AI work?",
      content1:
        " DocuSynth AI utilizes cutting-edge AI integrated with Optical Character Recognition (OCR) technology to understand the context, manage complex document layouts, and accurately process handwriting. This results in faster, more reliable document processing.",
    },

    {
      title: "What are the key features of DocuSynth AI?",
      content1: `
        <ul class="list-disc">
          <li>Innovative AI-OCR Technology: Provides unmatched accuracy and automation.</li>
          <li>User-Friendly Interface: Simplifies document digitization for users of all skill 
          levels.</li>
          <li>Secure & Efficient: Ensures data integrity while optimizing operations.</li>
          <li>Cost-Reducing: Significantly lowers operational expenses.</li>
        </ul>`,
    },

    {
      title: "What benefits can I expect from using DocuSynth AI?",
      content1: `
        <ul class="list-disc">
          <li>Over 50% Cost Savings: By improving invoice processing and eliminating errors.</li>
          <li>Time Efficiency: Streamlines data capture, making document handling quicker.</li>
          <li>Scalability: Adapts to businesses of all sizes, from small firms to large 
          enterprises.</li>
          <li>Strategic Advantage: Frees up resources, allowing companies to focus on growth
          and development.</li>
        </ul>`,
    },

    {
      title: "Is DocuSynth AI suitable for small businesses?",
      content1:
        "Yes, DocuSynth AI is designed to be scalable and can adapt to businesses of all sizes,from small firms to large enterprises. It provides a strategic advantage by freeing up resources and improving efficiency.",
    },

    {
      title: "How secure is DocuSynth AI?",
      content1:
        "DocuSynth AI prioritizes data security and integrity, ensuring that all processed documents are handled with the highest levels of security to protect sensitive information.",
    },

    {
      title: " How does DocuSynth AI integrate with my existing systems?",
      content1:
        "DocuSynth AI is designed to seamlessly integrate with a wide range of existing systems and workflows. Our team provides support to ensure a smooth integration process tailored to your specific needs.",
    },

    {
      title: "Can DocuSynth AI handle handwritten documents?",
      content1:
        "Yes, DocuSynth AI is equipped with advanced OCR technology that can accurately process handwritten documents, making it versatile for various types of document layouts and contexts.",
    },

    {
      title:
        "How can DocuSynth AI benefit construction companies specifically?",
      content1:
        "DocuSynth AI helps construction companies by automating the processing of various financial documents, such as invoices, contracts, and purchase orders. This reduces manual data entry, minimizes errors, and ensures timely payments, which is crucial for maintaining project timelines and budgets.",
    },

    {
      title:
        "Can DocuSynth AI handle the complex layouts of construction documents?",
      content1:
        "Yes, DocuSynth AI is designed to manage complex document layouts often found in the construction industry. Its advanced AI and OCR capabilities can accurately interpret and process detailed invoices, blueprints, and handwritten notes, ensuring no critical information is missed.",
    },

    {
      title:
        "How does DocuSynth AI improve project management for construction companies?",
      content1:
        "By automating document processing, DocuSynth AI frees up time for project managers  to focus on core activities. The efficiency gained allows for better resource allocation, faster decision-making, and improved project tracking and reporting.",
    },

    {
      title:
        " Is the AI in DocuSynth AI adaptable to different types of construction documents?",
      content1:
        "Absolutely. DocuSynth AI's machine learning algorithms are continuously improving and can adapt to various document types, including invoices, contracts, work orders, and more. This adaptability ensures consistent performance regardless of the document format or content.",
    },

    {
      title:
        "What support is available for construction companies implementing DocuSynth AI?",
      content1:
        "We provide comprehensive support to ensure smooth implementation. This includes initial setup assistance, training for your staff, and ongoing technical support. Our team works closely with you to tailor DocuSynth AI to your specific document processing needs and ensure maximum benefit from our technology.",
    },

    {
      title: "How can I get started with DocuSynth AI?",
      content1:
        "To get started, contact our sales team for a consultation. We will assess your specific needs and demonstrate how DocuSynth AI can transform your document processing operations.",
      content2:
        "If you have any additional questions, feel free to reach out to our support team at support@docusynthai.com.",
    },
  ];

  return (
    <div className="pb-[100px] xl:pb-[0px]">
      <div className="container-class">
        <div className="flex justify-center flex-col items-center pt-[20px]">
          <Fade left>
            <h1 className="text-[#222] text-center font-medium text-[30px] md:text-[36px] xl:text-[40px] w-[311px]">
              Your Questions, Answered
              <span className="text-[#00D588]">!</span>
            </h1>
          </Fade>
        </div>
      </div>
      <div className="faq-bg mt-[250px] xl:mt-[550px] mb-[80px] xl:mb-[100px] relative">
        <div className="absolute top-[-200px] xl:top-[-500px] right-0 left-0 mx-auto max-w-[90%] xl:max-w-[1200px]">
          <div className="bg-[#004862] rounded-[10px] w-full p-[50px] h-[500px] xl:h-[780px]">
            <div className="relative  overflow-auto max-h-full">
              {accordionData.map((item, index) => (
                <div key={index} className="mb-4">
                  <div
                    className="flex items-center justify-between cursor-pointer py-[20px] w-[98%]"
                    onClick={() => toggleAccordion(index)}
                  >
                    <h1 className="text-[16px] md:text-[18px] xl:text-[20px] text-white font-semibold">
                      {index + 1}. {item.title}
                    </h1>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                      className={`transform transition-transform duration-300 ${
                        openIndex === index ? "rotate-180" : "rotate-0"
                      }`}
                    >
                      <path
                        d="M6.5 7L0.5 0.863014L1.34375 0L6.5 5.27397L11.6562 0L12.5 0.863014L6.5 7Z"
                        fill="#00D588"
                      />
                    </svg>
                  </div>
                  <div
                    className={`overflow-hidden transition-all duration-300 ${
                      openIndex === index ? "max-h-screen" : "max-h-0"
                    }`}
                  >
                    <div
                      className={` ${
                        openIndex === index ? "pb-[30px]" : "pb-0"
                      }`}
                    >
                      <div
                        className="text-[#CCC] text-[14px] md:text-[16px] xl:text-[18px] font-normal"
                        dangerouslySetInnerHTML={{ __html: item.content1 }}
                      />
                      {item.content2 && (
                        <div
                          className="text-[#CCC] text-[14px] md:text-[16px] xl:text-[18px] font-normal pt-[30px]"
                          dangerouslySetInnerHTML={{ __html: item.content2 }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
