import React, { useRef, useState } from "react";
import BusinessSolutionImage1 from "../../assets/images/commonImages/Group 5412.svg";
import BusinessSolutionImage2 from "../../assets/images/commonImages/Group 5415.svg";
import BusinessSolutionImage3 from "../../assets/images/commonImages/Group 5403.svg";
import BusinessSolutionImage4 from "../../assets/images/commonImages/Group 5421.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Fade } from "react-reveal";
const BusinessSolution = ({ backgroundColor }) => {
  const [arrowHover1, setArrowHover1] = useState(false);
  const [arrowHover2, setArrowHover2] = useState(false);
  const carouselRef = useRef(null);
  const handleMouseEnter1 = () => {
    setArrowHover1(true);
  };

  const handleMouseLeave1 = () => {
    setArrowHover1(false);
  };

  const handleMouseEnter2 = () => {
    setArrowHover2(true);
  };

  const handleMouseLeave2 = () => {
    setArrowHover2(false);
  };

  const nextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  const prevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1450 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1450, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 699, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <div className="container-class py-[80px] xl:py-[100px]">
        <div className="flex  justify-between flex-col">
          <div className=" flex flex-col xl:flex-row items-start justify-between h-[150px] xl:h-[250px]">
            <Fade left delay={400}>
              <h2 className="w-full pr-0 xl:pr-3 xl:w-[510px] text-[#222] text-[24px] md:text-[30px] xl:text-[40px] font-medium">
                We provide the best solution for your business development
                <span className="text-[#00D588]">.</span>
              </h2>
            </Fade>
            <div>
              <Fade right delay={400}>
                <p className="text-[#222] w-full xl:w-[515px]  text-[14px] md:text-[16px] xl:text-[18px] font-medium">
                  A one stop shop for Construction Companies to manage their
                  invoice process.
                </p>
              </Fade>
            </div>
          </div>
          <div className="flex flex-col justify-between pb-[50px] xl:pb-[80px] pt-[50px] xl:pt-[0px]">
            <div className="flex items-center justify-center xl:justify-end gap-[20px]">
              <div
                onClick={prevSlide}
                onMouseEnter={handleMouseEnter1}
                onMouseLeave={handleMouseLeave1}
                style={{
                  backgroundColor: arrowHover1 ? "#004862" : "#0048620d",
                }}
                className="w-[50px] h-[50px]  rounded-[50px] cursor-pointer"
              >
                <div className="flex items-center justify-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="9"
                    viewBox="0 0 17 9"
                    fill={arrowHover1 ? "#00D588" : "#004862"}
                  >
                    <path
                      d="M0.191568 3.58477C0.191765 3.58458 0.191929 3.58435 0.192156 3.58415L3.60269 0.190067C3.85819 -0.064196 4.27146 -0.0632498 4.52578 0.192286C4.78008 0.447788 4.7791 0.861051 4.5236 1.11535L2.23376 3.39408H16.0568C16.4173 3.39408 16.7095 3.6863 16.7095 4.04679C16.7095 4.40728 16.4173 4.6995 16.0568 4.6995H2.2338L4.52356 6.97824C4.77907 7.23253 4.78005 7.64579 4.52575 7.9013C4.27142 8.15687 3.85813 8.15775 3.60266 7.90352L0.192123 4.50943C0.191929 4.50924 0.191765 4.50901 0.191536 4.50881C-0.0640965 4.25367 -0.0632801 3.83907 0.191568 3.58477Z"
                      fill={arrowHover1 ? "#00D588" : "#004862"}
                    />
                  </svg>
                </div>
              </div>
              <div
                onClick={nextSlide}
                onMouseEnter={handleMouseEnter2}
                onMouseLeave={handleMouseLeave2}
                style={{
                  backgroundColor: arrowHover2 ? "#004862" : "#0048620d",
                }}
                className="w-[50px] h-[50px]  rounded-[50px] cursor-pointer"
              >
                <div className="flex items-center justify-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="9"
                    viewBox="0 0 17 9"
                    fill={arrowHover2 ? "#00D588" : "#004862"}
                  >
                    <path
                      d="M16.5179 3.58477C16.5177 3.58458 16.5175 3.58435 16.5173 3.58415L13.1068 0.190067C12.8513 -0.064196 12.438 -0.0632498 12.1837 0.192286C11.9294 0.447788 11.9304 0.861051 12.1859 1.11535L14.4757 3.39408H0.652709C0.292218 3.39408 0 3.6863 0 4.04679C0 4.40728 0.292218 4.6995 0.652709 4.6995H14.4757L12.1859 6.97824C11.9304 7.23253 11.9294 7.64579 12.1837 7.9013C12.4381 8.15687 12.8513 8.15775 13.1068 7.90352L16.5173 4.50943C16.5175 4.50924 16.5177 4.50901 16.5179 4.50881C16.7736 4.25367 16.7728 3.83907 16.5179 3.58477Z"
                      fill={arrowHover2 ? "#00D588" : "#004862"}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Carousel
          ref={carouselRef}
          arrows={false}
          responsive={responsive}
          infinite={true}
        >
          <div className="pr-[20px]">
            <img src={BusinessSolutionImage1} alt="BusinessSolutionImage1" />
            <div className="pt-[28px]">
              <h1 className="text-[#004862]  text-[14px] md:text-[18px] xl:text-[20px] font-[600] tracking-wide">
                DocuSynth AI InvoiceFlow
              </h1>
              <p className="w-[fit-content] xl:w-[310px] text-[12px] md:text-[14px] xl:text-[16px] font-light text-[#222] pt-2.5">
                AI algorithms consolidate invoices into standardized statements,
                reducing manual effort and errors while enhancing the customer
                experience.
              </p>
            </div>
          </div>
          <div className="pr-[20px]">
            <img src={BusinessSolutionImage2} alt="BusinessSolutionImage1" />
            <div className="pt-[28px]">
              <h1 className="text-[#004862] text-[14px] md:text-[18px] xl:text-[20px] font-[600] tracking-wide">
                DocuSynth AI-Enabled Time and Expense Tracking
              </h1>
              <p className="w-[fit-content] xl:w-[310px] text-[12px] md:text-[14px] xl:text-[16px] font-light text-[#222] pt-2.5">
                DocuSynth AI-Invoice Flow automates invoice preparation by
                tracking billable hours and expenses in real-time. It suggests
                entries using historical data, reducing manual work and errors.
              </p>
            </div>
          </div>
          <div className="pr-[20px]">
            <img src={BusinessSolutionImage3} alt="BusinessSolutionImage1" />
            <div className="pt-[28px]">
              <h1 className="w-[302px] text-[#004862] text-[14px] md:text-[18px] xl:text-[20px] font-[600] tracking-wide">
                Advanced Client Portal with AI Features
              </h1>
              <p className="w-[fit-content] xl:w-[310px] text-[12px] md:text-[14px] xl:text-[16px] font-light text-[#222] pt-2.5">
                Offers real-time transparency, fosters trust, reduces disputes,
                and encourages prompt payment. It also provides insights for
                cost optimization, improving satisfaction and loyalty.
              </p>
            </div>
          </div>
          <div className="pr-[20px]">
            <img src={BusinessSolutionImage4} alt="BusinessSolutionImage1" />
            <div className="pt-[28px]">
              <h1 className="w-[226px] text-[#004862] text-[14px] md:text-[18px] xl:text-[20px] font-[600] tracking-wide">
                Unified, AI-Enhanced Invoicing Ecosystem
              </h1>
              <p className="w-[fit-content] xl:w-[310px] text-[12px] md:text-[14px] xl:text-[16px] font-light text-[#222] pt-2.5">
                A cohesive ecosystem, streamlining the entire invoicing process
                from time and expense tracking to invoice generation and client
                approval.
              </p>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default BusinessSolution;
