import React from 'react'
import Header from "../utils/header"
import Privacy from '../components/privacy'
import Footer from "../utils/footer"
import { Helmet } from "react-helmet"

const PrivacyPolicy = () => {
  return (
    <div>
    <Helmet>
        <title>Docusynth - PrivacyPolicy</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
    </Helmet>
        <Header/>
        <Privacy/>
        <Footer/>
    </div>
  )
}

export default PrivacyPolicy
