import React from "react";
import Header from "../utils/header";
import Blog2 from "../components/blog2";
import Footer from "../utils/footer";
import { Helmet } from "react-helmet"

const Blog2Page = () => {
  return (
    <div>
    <Helmet>
        <title>Docusynth - Blog2</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
    </Helmet>
      <Header />
      <Blog2 />
      <Footer display="none" />
    </div>
  );
};

export default Blog2Page;
