import React, { useEffect, useRef, useState } from "react";
import ServeYouBestImage1 from "../../assets/images/home/Group 5416.png";
import ServeYouBestImage2 from "../../assets/images/home/Group 5417.png";
import ServeYouBestImage3 from "../../assets/images/home/Group 5418.png";
import ServeYouBestImage4 from "../../assets/images/home/Group 5425.png";
import Carousel from "react-multi-carousel";
import { Fade } from "react-reveal";
const ServeYouBest = ({ paddingBottom }) => {
  const [arrowHover1, setArrowHover1] = useState(false);
  const [arrowHover2, setArrowHover2] = useState(false);
  const carouselRef = useRef(null);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1450 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1450, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 699, min: 0 },
      items: 1,
    },
  };
  const nextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  const prevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const handleMouseEnter1 = () => {
    setArrowHover1(true);
  };

  const handleMouseLeave1 = () => {
    setArrowHover1(false);
  };

  const handleMouseEnter2 = () => {
    setArrowHover2(true);
  };

  const handleMouseLeave2 = () => {
    setArrowHover2(false);
  };

  const [paddingTop, setPaddingTop] = useState("0px");
  useEffect(() => {
    const updatePadding = () => {
      const width = window.innerWidth;

      if (width >= 1450) {
        setPaddingTop("250px");
      } else if (width >= 1024) {
        setPaddingTop("220px");
      } else if (width >= 768) {
        setPaddingTop("180px");
      } else {
        setPaddingTop("130px");
      }
    };

    updatePadding(); // Set padding on initial render
    window.addEventListener("resize", updatePadding); // Update padding on resize

    return () => {
      window.removeEventListener("resize", updatePadding); // Cleanup listener
    };
  }, []);
  return (
    <div
      className="container-class  "
      style={{
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
      }}
    >
      <div className="flex  xl:items-center xl:flex-row flex-col items-stretch justify-between gap-[20px] ">
        <div className="">
          <Fade left>
            <h2 className="w-full xl:w-[608px] text-[#222] text-[24px] md:text-[30px] xl:text-[40px] font-medium">
              What we do to serve you best{" "}
              <span className="text-[#00D588]">.</span>
            </h2>
            <div className="pt-[20px]">
              <p className="text-[#222] w-full xl:w-[490px]  text-[14px] md:text-[16px] xl:text-[18px] font-[600]">
                Is bill line item data entry overwhelming?
              </p>
              <p className="text-[#222]  xl:w-[490px] text-[18px] font-[300]">
                Eliminate the need for any manual data entry with
              </p>
            </div>
          </Fade>
        </div>
        <div className=" flex flex-col justify-between  ">
          <div className="flex items-center justify-center lg:justify-end gap-[20px]">
            <Fade right>
              <div
                onClick={prevSlide}
                onMouseEnter={handleMouseEnter1}
                onMouseLeave={handleMouseLeave1}
                style={{
                  backgroundColor: arrowHover1 ? "#004862" : "#0048620d",
                }}
                className="w-[50px] h-[50px]  rounded-[50px] cursor-pointer"
              >
                <div className="flex items-center justify-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="9"
                    viewBox="0 0 17 9"
                    fill={arrowHover1 ? "#00D588" : "#004862"}
                  >
                    <path
                      d="M0.191568 3.58477C0.191765 3.58458 0.191929 3.58435 0.192156 3.58415L3.60269 0.190067C3.85819 -0.064196 4.27146 -0.0632498 4.52578 0.192286C4.78008 0.447788 4.7791 0.861051 4.5236 1.11535L2.23376 3.39408H16.0568C16.4173 3.39408 16.7095 3.6863 16.7095 4.04679C16.7095 4.40728 16.4173 4.6995 16.0568 4.6995H2.2338L4.52356 6.97824C4.77907 7.23253 4.78005 7.64579 4.52575 7.9013C4.27142 8.15687 3.85813 8.15775 3.60266 7.90352L0.192123 4.50943C0.191929 4.50924 0.191765 4.50901 0.191536 4.50881C-0.0640965 4.25367 -0.0632801 3.83907 0.191568 3.58477Z"
                      fill={arrowHover1 ? "#00D588" : "#004862"}
                    />
                  </svg>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div
                onClick={nextSlide}
                onMouseEnter={handleMouseEnter2}
                onMouseLeave={handleMouseLeave2}
                style={{
                  backgroundColor: arrowHover2 ? "#004862" : "#0048620d",
                }}
                className="w-[50px] h-[50px]  rounded-[50px] cursor-pointer"
              >
                <div className="flex items-center justify-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="9"
                    viewBox="0 0 17 9"
                    fill={arrowHover2 ? "#00D588" : "#004862"}
                  >
                    <path
                      d="M16.5179 3.58477C16.5177 3.58458 16.5175 3.58435 16.5173 3.58415L13.1068 0.190067C12.8513 -0.064196 12.438 -0.0632498 12.1837 0.192286C11.9294 0.447788 11.9304 0.861051 12.1859 1.11535L14.4757 3.39408H0.652709C0.292218 3.39408 0 3.6863 0 4.04679C0 4.40728 0.292218 4.6995 0.652709 4.6995H14.4757L12.1859 6.97824C11.9304 7.23253 11.9294 7.64579 12.1837 7.9013C12.4381 8.15687 12.8513 8.15775 13.1068 7.90352L16.5173 4.50943C16.5175 4.50924 16.5177 4.50901 16.5179 4.50881C16.7736 4.25367 16.7728 3.83907 16.5179 3.58477Z"
                      fill={arrowHover2 ? "#00D588" : "#004862"}
                    />
                  </svg>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      {/* cards */}
      <Carousel
        ref={carouselRef}
        arrows={false}
        responsive={responsive}
        infinite={true}
        className="pt-[60px]"
      >
        <div className="mr-[24px]">
          <div className="border border-[#CCC] rounded-[10px]  pt-[30px] px-[30px] h-[370px]">
            <img src={ServeYouBestImage1} alt="BusinessSolutionImage1" />
            <div className="pt-[38px]">
              <h1 className="text-[#004862]  text-[14px] md:text-[16px] xl:text-[20px] font-[600] tracking-wide">
                Innovative AI-OCR Technology
              </h1>
              <p className="w-full xl:w-[310px] text-[16px] font-light text-[#222] pt-2.5">
                Reduce admin work tied to invoice approvals and enable employees
                to take on new strategic initiatives.
              </p>
            </div>
          </div>
        </div>

        <div className="mr-[24px] ">
          <div className="border border-[#CCC] rounded-[10px]  pt-[30px] px-[30px] h-[370px] ">
            <img src={ServeYouBestImage2} alt="BusinessSolutionImage1" />
            <div className="pt-[38px]">
              <h1 className="text-[#004862] text-[14px] md:text-[16px] xl:text-[20px] font-[600] tracking-wide">
                User-Friendly
              </h1>
              <p className="w-full xl:w-[310px] text-[16px] font-light text-[#222] pt-2.5">
                Automate your workflows and gain better insight into the status
                of approvals and payments while reducing costs tied to errors or
                late disbursements.
              </p>
            </div>
          </div>
        </div>
        <div className="mr-[24px]">
          <div className="border border-[#CCC] rounded-[10px]  pt-[30px] px-[30px] h-[370px]">
            <img src={ServeYouBestImage3} alt="BusinessSolutionImage1" />
            <div className="pt-[38px]">
              <h1 className="text-[#004862]  text-[14px] md:text-[16px] xl:text-[20px]] font-[600] tracking-wide">
                Secure & Efficient
              </h1>
              <p className="w-full xl:w-[310px] text-[16px] font-light text-[#222] pt-2.5">
                Gain flexibility to tackle AP tasks from anywhere through a 24/7
                online platform that enables employees to be more productive and
                avoid delays.
              </p>
            </div>
          </div>
        </div>
        <div className="mr-[24px]">
          <div className="border border-[#CCC] rounded-[10px]  pt-[30px] px-[30px] h-[370px]">
            <img src={ServeYouBestImage4} alt="BusinessSolutionImage1" />
            <div className="pt-[38px]">
              <h1 className="text-[#004862]  text-[14px] md:text-[16px] xl:text-[20px] font-[600] tracking-wide">
                Cost Reduction
              </h1>
              <p className="w-full xl:w-[310px] text-[16px] font-light text-[#222] pt-2.5">
                We help you to reduce cost upto 50% by improving invoice
                processing and eliminating errors.
              </p>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default ServeYouBest;
