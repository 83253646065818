import React from "react";
import BannerImage from "../../assets/images/home/Images.png";
import { Fade } from "react-reveal";
const banner = () => {
  return (
    <div className="pt-[140px] xl:pt-[192px] ">
      <div className="container-class flex">
        <div className="w-[640px]">
          <Fade left>
            <p className="text-[#004862] text-[44px] xl:text-[68px] font-bold leading-[40px] xl:leading-[50px]">
              <h3>
                DocuSynth A<span className="green-i">i</span>
              </h3>
              <span className="text-[#222] text-[24px] xl:text-[44px] font-[400] ">
                {" "}
                Revolutionizing Construction Companies with AI-powered OCR
              </span>
            </p>
            <div className="pt-[30px] xl:pt-[40px]">
              <button
                onClick={() =>
                  window.open(
                    "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0jGjbL7bOqJUTUjceNTOwz2hKSCmEYxGuZi06_-qOB3fgA2-6nekA2qAOpEKvzg3m3LtNQDp6k",
                    "_blank",
                  )
                }
                type="button"
                className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[8px] px-[28px] font-[400] rounded-[6px] text-[14px] xl:text-[16px]"
              >
                Book a Meeting
              </button>
            </div>
          </Fade>
        </div>
        <div className="absolute right-0 pt-[300px] md:pt-[100px] xl:pt-[90px] ">
          <img src={BannerImage} alt="BannerImage" />
        </div>
      </div>
    </div>
  );
};

export default banner;
