import React, { useState } from "react";
import LoginLogo from "../assets/images/userDetails/Group.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  console.log("formErrors", formErrors);
  const requiredFields = ["email"];

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (forgotPassword.email && !emailRegex.test(forgotPassword.email)) {
      errors.email = "Enter a valid email address";
    }
    return Object.keys(errors).length === 0;
  };

  const PasswordHandler = (name, value) => {
    setForgotPassword({
      ...forgotPassword,
      [name]: value,
    });
  };

  const submitForm = async (e) => {
    console.log("submitForm", e);
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          "https://documentai-dev.akru.co/api/user/forgetPassword",
          forgotPassword
        );
        console.log("submitForm response", response);
        navigate("/log-in");
        setForgotPassword({
          email: "",
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error in API", error);
      }
    } else {
      const emptyFields = requiredFields.filter(
        (fieldName) =>
          !forgotPassword[fieldName] || !forgotPassword[fieldName].trim()
      );
      const fieldErrors = {};
      emptyFields.forEach((fieldName) => {
        fieldErrors[fieldName] = true;
      });
      setFormErrors(fieldErrors);
    }
  };

  return (
    <div className="flex ">
        <div className="login-bg w-[45%]">
        <div className="h-[100vh]  w-full flex justify-center items-center">
          <div>
            <div className="flex justify-center">
              <img src={LoginLogo} alt="LoginLogo" />
            </div>
            <div className="flex items-center justify-center pt-[50px]">
              <p className="text-white font-[400]  text-[40px] md:text-[44px]  xl:text-[48.629px]  cursor-pointer">
                DocuSynth Ai
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-[55%] justify-center ">
        <div className="pt-[200px]">
          <div className="pt-[70px]">
            <p className="text-black font-[600] text-[52px]">
              Forgot Your Password
            </p>
            <div className="flex justify-center pt-[18px]">
              <p className="text-[#666] font-[400] text-[18px] text-center w-[376px]">
                Enter your email below to receive a password reset link.
              </p>
            </div>
          </div>
          <form onSubmit={submitForm} className="pt-[30px]">
            <div
              className="rounded-[20px] p-[30px]"
              style={{
                background:
                  "linear-gradient(125deg, rgba(255, 255, 255, 0.40) 15.83%, rgba(255, 255, 255, 0.55) 100%)",
                backdropFilter: "blur(5px)",
              }}
            >
              <div className="mb-[24px]">
                <input
                  value={forgotPassword.email}
                  onChange={(e) => PasswordHandler("email", e.target.value)}
                  required
                  type="email"
                  name="email"
                  className="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                  placeholder="Your Email"
                  style={{
                    background:
                      "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                    backdropFilter: "blur(17.5px)",
                  }}
                />
              </div>

              <div className="flex justify-center">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="p-[18px] text-white text-[16px] font-[600] cursor-pointer border border-[#004862] rounded-[5px] bg-[#004862] text-center"
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                    </svg>
                  ) : (
                    <span>Reset Password</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
