import React from "react";
import Header from "../utils/header";
import Footer from "../utils/footer";
import Banner from "../components/pricing/banner";
import { Helmet } from "react-helmet"

const Pricing = () => {
  return (
    <div>
    <Helmet>
        <title>Docusynth - Pricing</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
    </Helmet>
      <Header />
      <Banner />
      <Footer display="none" />
    </div>
  );
};

export default Pricing;
