import React from "react";
import ContactImage from "../../assets/images/contact/mapp 1.png";
const Banner = () => {
  return (
    <div className="container-class">
      <div className="py-[60px] md:py-[120px] xl:py-[160px]">
        <img src={ContactImage} alt="ContactImage" className="w-full" />
      </div>
    </div>
  );
};

export default Banner;
