import React from "react";
import Header from "../utils/header";
import Blogs from "../components/blogs";
import Footer from "../utils/footer";
import { Helmet } from "react-helmet"

const BlogsPage = () => {
  return (
    <div>
    <Helmet>
        <title>Docusynth - Blogs</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
    </Helmet>
      <Header />
      <Blogs />
      <Footer display="none" />
    </div>
  );
};

export default BlogsPage;
