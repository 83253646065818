import React from "react";
import { Fade } from "react-reveal";

const AboutMarket = () => {
  return (
    <div className=" bg-[#004862] w-full">
      <div className="container-class">
        <div className="gap-[20px] xl:gap- flex items-center flex-wrap justify-center xl:justify-between pt-[80px]  xl:pt-[60px] pb-[80px] xl:pb-[60px]">
         <Fade left delay={400}>
          <div className="text-center xl:text-start w-full md:w-[50%] xl:w-[163px] ">
            <h1 className="text-[#00D588] text-[24px] md:text-[30px] xl:text-[36px] font-medium xl:font-semibold ">
              90%
            </h1>
            <p className="text-[16px] md:text-[18px] xl:text-[20px] font-medium text-white pt-2.5  ">
              Error reduction
            </p>
          </div>
          </Fade>
          <Fade bottom delay={400}>
          <div className="text-center  w-full md:w-[50%] xl:w-[300px]">
            <h1 className="text-[#00D588] text-[24px] md:text-[30px] xl:text-[36px] font-medium xl:font-semibold  ">
              75%
            </h1>
            <p className="text-[16px] md:text-[18px] xl:text-[20px] font-medium text-white pt-2.5 ">
              Reduction in processing time
            </p>
          </div>
          </Fade>
          <Fade right delay={400}>
          <div className="w-full md:w-[50%] xl:w-[338px] text-center xl:text-end ">
            <h1 className=" text-[#00D588] text-[24px] md:text-[30px] xl:text-[36px] font-medium xl:font-semibold ">
              85%
            </h1>
            <p className="text-[16px] md:text-[18px] xl:text-[20px] font-medium text-white pt-2.5 ">
              Improved customer satisfaction
            </p>
          </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default AboutMarket;
