import React, { useState } from "react";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";

const OurPricing = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [active, setActive] = useState("Monthly");

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };
  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const handlePricingClicked = (plan) => {
    navigate(`/pricing?plan=${plan}&period=${active}`);
    window.scroll(0, 0);
  };
  return (
    <div id="pricing" className="container-class">
      <Fade bottom>
        <div className="flex flex-col items-center ">
          <h1 className="text-[#222] text-[24px] md:text-[30px] xl:text-[40px] font-medium">
            Our Pricing<span className="text-[#00D588]">.</span>
          </h1>
          <p className="w-full xl:w-[690px] text-[#222] font-light text-[14px] md:text-[16px] xl:text-[18px] text-center pt-[20px]">
            Flexible monthly subscription rates starting at $99 for 100
            documents scaling upto unlimited documents, making our technology
            accessible to businesses of various size.
          </p>
        </div>
      </Fade>

      <div className="pt-[60px] flex justify-center">
        <div className="border border-[#ccc] flex  items-center justify-between rounded-[50px] h-[48px] xl:h-[60px] w-full xl:w-[30%]">
          <div
            className={`w-[49%] text-center  py-[12px] xl:py-[18px] cursor-pointer ${
              active === "Monthly"
                ? "bg-[#004862] rounded-[50px]"
                : "bg-[transparent] rounded-[50px]"
            }`}
            onClick={() => setActive("Monthly")}
          >
            <button
              className={`text-[14px] font-medium ${
                active === "Monthly" ? "text-[#00D588]" : "text-[#666] "
              }`}
            >
              Monthly
            </button>
          </div>
          <div
            className={`w-[49%] text-center  pt-[8px] xl:pt-[10px] pb-[8px] xl:pb-[17px] flex justify-center items-center cursor-pointer ${
              active === "Yearly"
                ? "bg-[#004862] rounded-[50px]"
                : "bg-[transparent] rounded-[50px]"
            }`}
            onClick={() => setActive("Yearly")}
          >
            <button
              className={`text-[14px] font-medium ${
                active === "Yearly" ? "text-[#00D588]" : "text-[#666] "
              }`}
            >
              Yearly
              <div className="flex pb-[10px]">
                <span
                  className={`text-[10px] font-normal h-0 ${
                    active === "Yearly"
                      ? "text-[#00D588]"
                      : "text-[#666] group-hover:text-[#00D588]"
                  }`}
                >
                  Upto 25% off
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="py-[60px]">
        <div className="flex items-start flex-wrap justify-between gap[20px] xl:gap-[0px] ">
          <Fade top>
            <div
              onClick={() => handlePricingClicked("Starter")}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={`w-full md:w-[49%] xl:w-[30%] h-[586px] border border-[#CCC] rounded-[10px] ${
                isHovered ? "bg-[#004862]" : "bg-white"
              } cursor-pointer`}
            >
              <div className="flex flex-col items-center pt-[50px] pb-[18px]">
                <h1
                  className={`text-[26px] md:text-[30px] xl:text-[34px] ${
                    isHovered ? "text-white" : "text-[#222]"
                  } font-semibold`}
                >
                  Starter
                </h1>
                {active === "Monthly" ? (
                  <p className="text-[44px] text-[#00D588] font-[700]">
                    $99
                    <span
                      className={`text-[15px] ${
                        isHovered ? "text-white" : "text-[#222]"
                      }  font-normal`}
                    >
                      /Monthly
                    </span>
                  </p>
                ) : (
                  <>
                    <p className="text-[40px] text-[#00D588] font-[700]">
                      $75
                      <span
                        className={`text-[14px] ${
                          isHovered ? "text-white" : "text-[#222]"
                        }  font-normal`}
                      >
                        /Monthly
                      </span>
                    </p>
                    <p className="line-through text-[20px] text-slate-400	 font-[400]">
                      $99/Monthly
                    </p>
                    <p className="text-[26px] text-[#00D588] font-[700]">
                      Save $297
                      <span
                        className={`text-[14px] ${
                          isHovered ? "text-white" : "text-[#222]"
                        }  font-normal`}
                      >
                        /Yearly{" "}
                      </span>
                    </p>
                  </>
                )}
              </div>
              <div className="border border-[#ccc] w-full"></div>
              <div className="flex flex-col items-center pt-[50px] pb-[44px]">
                <p
                  className={`text-[18px] ${
                    isHovered ? "text-white" : "text-[#666]"
                  } font-normal`}
                >
                  100 Invoices
                </p>
                <p
                  className={`text-[18px] ${
                    isHovered ? "text-white" : "text-[#666]"
                  }  font-normal pt-[32px] w-[140px] text-center`}
                >
                  $1.5 per invoice over 100
                </p>
                <div className="pt-[58px]">
                  <button
                    type="button"
                    className={`${
                      isHovered ? "text-[#004862]" : "text-[#FFF]"
                    }  ${
                      isHovered ? "bg-white" : "bg-[#004862]"
                    } py-[12px] px-[40px] font-[400] rounded-[6px] text-[16px]`}
                  >
                    30-day Free Trial
                  </button>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div
              onClick={() => handlePricingClicked("Premiam")}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
              className={`w-full md:w-[49%] xl:w-[30%] h-[586px] border border-[#CCC] rounded-[10px] mt-[40px] md:mt-0 ${
                isHovered1 ? "bg-[#004862]" : "bg-white"
              } cursor-pointer`}
            >
              <div className="flex flex-col items-center pt-[50px] pb-[18px]">
                <h1
                  className={`text-[26px] md:text-[30px] xl:text-[34px] ${
                    isHovered1 ? "text-white" : "text-[#222]"
                  } font-semibold`}
                >
                  Premium
                </h1>
                {active === "Monthly" ? (
                  <p className="text-[44px] text-[#00D588] font-[700]">
                    $199
                    <span
                      className={`text-[15px] ${
                        isHovered1 ? "text-white" : "text-[#222]"
                      }  font-normal`}
                    >
                      /Monthly
                    </span>
                  </p>
                ) : (
                  <>
                    <p className="text-[40px] text-[#00D588] font-[700]">
                      $150
                      <span
                        className={`text-[14px] ${
                          isHovered1 ? "text-white" : "text-[#222]"
                        }  font-normal`}
                      >
                        /Monthly
                      </span>
                    </p>
                    <p className="line-through text-[20px] text-slate-400 font-[400]">
                      $199/Monthly
                    </p>
                    <p className="text-[26px] text-[#00D588] font-[700]">
                      Save $597
                      <span
                        className={`text-[14px] ${
                          isHovered1 ? "text-white" : "text-[#222]"
                        }  font-normal`}
                      >
                        /Yearly{" "}
                      </span>
                    </p>
                  </>
                )}
              </div>
              <div className="border border-[#ccc] w-full"></div>
              <div className="flex flex-col items-center pt-[50px] pb-[44px]">
                <p
                  className={`text-[18px] ${
                    isHovered1 ? "text-white" : "text-[#666]"
                  } font-normal`}
                >
                  200 Invoices
                </p>
                <p
                  className={`text-[18px] ${
                    isHovered1 ? "text-white" : "text-[#666]"
                  }  font-normal pt-[32px] w-[150px] text-center`}
                >
                  $1.25 per invoice over 200
                </p>
                <div className="pt-[58px]">
                  <button
                    type="button"
                    className={`${
                      isHovered1 ? "text-[#004862]" : "text-[#FFF]"
                    }  ${
                      isHovered1 ? "bg-white" : "bg-[#004862]"
                    } py-[12px] px-[40px] font-[400] rounded-[6px] text-[16px]`}
                  >
                    30-day Free Trial
                  </button>
                </div>
              </div>
            </div>
          </Fade>
          <Fade top>
            <div
              onClick={() => handlePricingClicked("Enterprise")}
              onMouseEnter={handleMouseEnter2}
              onMouseLeave={handleMouseLeave2}
              className={`w-full md:w-[49%] xl:w-[30%] h-[586px]  border border-[#CCC] rounded-[10px] mt-[40px] xl:mt-0 ${
                isHovered2 ? "bg-[#004862]" : "bg-white"
              } cursor-pointer`}
            >
              <div className="flex flex-col items-center pt-[50px] pb-[18px]">
                <h1
                  className={`text-[26px] md:text-[30px] xl:text-[34px] ${
                    isHovered2 ? "text-white" : "text-[#222]"
                  } font-semibold`}
                >
                  Enterprise
                </h1>
                <p className="text-[24px] text-[#00D588] font-[700] pt-[20px] pb-[14px]">
                  Call For Pricing
                </p>
              </div>
              <div className="border border-[#ccc] w-full"></div>
              <div className="flex flex-col items-center pt-[82px] pb-[44px]">
                <p
                  className={`text-[18px] ${
                    isHovered2 ? "text-white" : "text-[#666]"
                  } font-normal w-[255px] text-center`}
                >
                  API Access $2500 monthly negotiable on invoice over 1,000 per
                  month
                </p>

                <div className="pt-[58px]">
                  <button
                    type="button"
                    className={`${
                      isHovered2 ? "text-[#004862]" : "text-[#FFF]"
                    }  ${
                      isHovered2 ? "bg-white" : "bg-[#004862]"
                    } py-[12px] px-[40px] font-[400] rounded-[6px] text-[16px]`}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default OurPricing;
