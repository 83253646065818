import axios from "axios";
import React, { useState } from "react";
import { Fade } from "react-reveal";
import Countries from "../../utils/countries";
import industries from "../../utils/industries.json";

const BookDemo = () => {
  const [bookDemo, setBookDemo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    insdustry: "",
    country: "",
  });

  const [formErrors, setFormErrors] = useState({});
  console.log("formErrors", formErrors);
  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "phone",
    "companyName",
    "insdustry",
    "country",
  ];
  const validateForm = () => {
    const errors = {};

    requiredFields.forEach((fieldName) => {
      const formattedFieldName = fieldName.replace("_", " ");

      if (!bookDemo[fieldName] || !bookDemo[fieldName].trim()) {
        errors[fieldName] = `${
          formattedFieldName.charAt(0).toUpperCase() +
          formattedFieldName.slice(1)
        } is required`;
      }
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (bookDemo.email && !emailRegex.test(bookDemo.email)) {
      errors.email = "Enter a valid email address";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormChanged = (name, value) => {
    setBookDemo({
      ...bookDemo,
      [name]: value,
    });
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      axios
        .post("https://documentai-dev.akru.co/api/user/bookDemo", bookDemo)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.error("Error in api", error);
        });
      setBookDemo({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        insdustry: "",
        country: "",
      });
    } else {
      const emptyFields = requiredFields.filter(
        (fieldName) => !bookDemo[fieldName] || !bookDemo[fieldName].trim()
      );
      const fieldErrors = {};
      emptyFields.forEach((fieldName) => {
        fieldErrors[fieldName] = true;
      });
      setFormErrors(fieldErrors);
    }
  };

  return (
    <div className="container-class pt-[60px] md:pt-[80px] xl:pt-[100px] ">
      <div className="flex flex-col items-center ">
        <div>
          <Fade left delay={800}>
            <h1 className="text-center text-[#222] text-[30px] md:text-[36px] xl:text-[40px] font-medium">
              Book a Demo
              <span className="text-[#00D588]">.</span>
            </h1>
            <p className="text-[#222] text-[14px] md:text-[16px] xl:text-[18px] font-[300] w-full xl:w-[496px]  text-center">
              Is bill line item data entry overwhelming? Eliminate the need for
              any manual data entry with
            </p>
          </Fade>
        </div>
        <Fade delay={900}>
          <form
            onSubmit={SubmitForm}
            className="bg-[#0048620d] mt-[60px] md:mt-[80px] xl:mt-[100px] rounded-[10px] w-full p-[30px] xl:p-[50px] flex  flex-wrap justify-between gap-[20px] xl:gap-[30px]"
          >
            <div className="w-full xl:w-[48%]">
              <input
                value={bookDemo.firstName}
                onChange={(e) => handleFormChanged("firstName", e.target.value)}
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="First Name"
                type="text"
                required
                name="firstName"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              <input
                value={bookDemo.lastName}
                onChange={(e) => handleFormChanged("lastName", e.target.value)}
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="Last Name"
                type="text"
                required
                name="lastName"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              <input
                value={bookDemo.phone}
                onChange={(e) => handleFormChanged("phone", e.target.value)}
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="Phone Number"
                type="number"
                required
                name="phone"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              <input
                value={bookDemo.email}
                onChange={(e) => handleFormChanged("email", e.target.value)}
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="Email"
                type="email"
                required
                name="email"
              />
            </div>
            <div className="w-full xl:w-[48%]">
              <input
                value={bookDemo.companyName}
                onChange={(e) =>
                  handleFormChanged("companyName", e.target.value)
                }
                className="w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500] outline-[#EEE]"
                placeholder="Company Name"
                type="text"
                required
                name="companyName"
              />
            </div>
            {/* <div className="w-full xl:w-[48%]">
              <select
                value={bookDemo.primaryAccountSystem}
                onChange={(e) =>
                  handleFormChanged("primaryAccountSystem", e.target.value)
                }
                className=" w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] text-[#666] text-[14px] font-[500] outline-[#EEE] appearance-none down-arrow"
                required
              >
                <option>Primary Accounting System</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div> */}
            <div className="w-full xl:w-[48%]">
              <select
                value={bookDemo.insdustry}
                onChange={(e) => handleFormChanged("insdustry", e.target.value)}
                className=" w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] text-[#666] text-[14px] font-[500] outline-[#EEE] appearance-none down-arrow"
                required
              >
                <option>Industry</option>
                {industries.map((option) => {
                  return (
                    <option key={option.id} value={option.name}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w-full xl:w-[48%]">
              <select
                value={bookDemo.country}
                onChange={(e) => handleFormChanged("country", e.target.value)}
                className=" w-full border border-[#EEE] rounded-[10px] py-[14px] pl-[20px] text-[#666] text-[14px] font-[500] outline-[#EEE] appearance-none down-arrow"
                required
              >
                <option>Country</option>
                {Countries.map((option) => {
                  return (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex justify-end w-full pt-[20px]">
              <button
                type="submit"
                className="text-[#004862] bg-[#00D588] hover:text-[#00D588] hover:bg-[#004862] py-[6px] xl:py-[8px] px-[32px] font-[400] rounded-[6px] text-[12px] md:text-[14px] xl:text-[16px]"
              >
                Let’s Talk
              </button>
            </div>
          </form>
        </Fade>
      </div>
    </div>
  );
};

export default BookDemo;
