import React from "react";
import LoginLogo from "../assets/images/userDetails/Group.svg";
import { useNavigate } from "react-router-dom";
const PasswordUpdated = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-100vh">
      <div className="login-bg w-[45%]">
        <div className="h-[inherit] w-full flex justify-center items-center">
          <div>
            <div className="flex justify-center">
              <img src={LoginLogo} alt="LoginLogo" />
            </div>
            <div className="flex items-center justify-center pt-[50px]">
              <p className="text-white font-[400] text-[48.629px] cursor-pointer">
                DocuSynth Ai
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex  w-[55%] justify-center ">
        <div className="pt-[200px]">
          <div className="pt-[70px]">
            <p className="text-black font-[600] text-[52px] text-center">
              Your Password Successfully
              <br /> Reset
            </p>
          </div>
          <div className="pt-[30px] ">
            <p className="text-center text-[#222] text-[20px] font-normal">
              Click here to{" "}
              <span
                onClick={() => navigate("/log-in")}
                className="text-[#004862] font-[600] cursor-pointer"
              >
                Log In
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordUpdated;
