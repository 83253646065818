import React from "react";
import LoginLogo from "../assets/images/userDetails/Group.svg";
import { Navigate, useNavigate } from "react-router-dom";
const ResetPassword = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-100vh">
      <div className="login-bg w-[45%]">
        <div className="h-[inherit] w-full flex justify-center items-center">
          <div>
            <div className="flex justify-center">
              <img src={LoginLogo} alt="LoginLogo" />
            </div>
            <div className="flex items-center justify-center pt-[50px]">
              <p className="text-white font-[400] text-[48.629px] cursor-pointer">
                DocuSynth Ai
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex  w-[55%] justify-center ">
        <div className="pt-[200px]">
          <div className="pt-[70px]">
            <p className="text-black font-[600] text-[52px]">
              Reset Your Password
            </p>
            <div className="flex justify-center pt-[18px]">
              <p className="text-[#666] font-[400] text-[18px]  text-center w-[376px]">
                Create a new Password
              </p>
            </div>
          </div>
          <div className="pt-[30px] ">
            <div
              className="rounded-[20px] p-[30px]"
              style={{
                background:
                  "linear-gradient(125deg, rgba(255, 255, 255, 0.40) 15.83%, rgba(255, 255, 255, 0.55) 100%)",
                backdropFilter: "blur(5px)",
              }}
            >
              <div class="mb-[14px]">
                <input
                  type="password"
                  class="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                  placeholder="Password"
                  style={{
                    background:
                      "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                    backdropFilter: "blur(17.5px)",
                  }}
                />
              </div>
              <div class="mb-[14px]">
                <input
                  type="password"
                  class="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                  placeholder="Confirm Password"
                  style={{
                    background:
                      "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                    backdropFilter: "blur(17.5px)",
                  }}
                />
              </div>

              <div
                onClick={() => navigate("/successfully-reset-password")}
                className="cursor-pointer border border-[#004862] rounded-[5px] bg-[#004862] text-center"
              >
                <button className="  py-[18px] text-white text-[16px] font-[600]">
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
