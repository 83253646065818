import React from "react";
import Header from "../utils/header";
import PaperInvoices from "../components/solutions/paperInvoices";
import BusinessSolution from "../components/home/businessSolution";
import ServeYouBest from "../components/home/serveYouBest";
import Footer from "../utils/footer";
import { Helmet } from "react-helmet"

const Solution = () => {
  return (
    <div>
    <Helmet>
        <title>Docusynth - Solution</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
    </Helmet>
      <Header />
      <BusinessSolution backgroundColor="white" />
      <PaperInvoices />
      <ServeYouBest paddingTop="0px" paddingBottom="100px" />
      <Footer />
    </div>
  );
};

export default Solution;
