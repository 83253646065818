import React from "react";
import OurProductImage from "../../assets/images/home/productnew 1.png";
import { Fade } from "react-reveal";
const OurProducts = () => {
  return (
    <div className="ourProduct-bg">
      <div className="relative">
        <div className="container-class flex flex-col xl:flex-row items-center h-[fit-content] xl:h-[777px] pt-[50px] xl:pt-[0px]">
          <Fade left>
            <h1 className="text-white text-[24px] md:text-[30px] xl:text-[40px] font-medium ">
              Our product<span className="text-[#00D588]">.</span>
            </h1>
          </Fade>
        </div>

        <div className="absolute md:top-[10px] lg:top-[0px] xl:top-[-110px] right-[0px] ">
          <img src={OurProductImage} alt="OurProductImage" className="h-fit" />
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
