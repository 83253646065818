import React from "react";
import Banner from "../components/home/banner";
import Header from "../utils/header";
import AccountPayable from "../components/home/accountPayable";
import Procedure from "../components/home/procedure";
import BusinessSolution from "../components/home/businessSolution";
import OurProducts from "../components/home/ourProducts";
import ServeYouBest from "../components/home/serveYouBest";
import OurPricing from "../components/home/ourPricing";
import Footer from "../utils/footer";
// import ClientReview from "../components/clientReview";
import Faqs from "../components/faqs";
import { Helmet } from "react-helmet"

const HomePage = () => {
  return (
    <div>
    <Helmet>
        <title>Docusynth - HomePage</title>
        <meta name="og:description" content="" />
        <meta name="description" content="" />
    </Helmet>
      <Header />
      <Banner />
      <AccountPayable />
      <Procedure />
      <BusinessSolution backgroundColor="#0048620d" />
      <OurProducts />
      <ServeYouBest paddingBottom="150px" />
      <OurPricing />
      {/* <ClientReview /> */}
      <Faqs />
      <Footer />
    </div>
  );
};

export default HomePage;
