import React from "react";

import { Fade } from "react-reveal";

const Improving = () => {
  return (
    <div className="container-class pt-[80px] xl:pt-[60px] pb-[80px] xl:pb-[150px]">
      <div className="flex items-start flex-col xl:flex-row justify-between">
        <Fade left>
          <div className="w-full xl:w-[30%] pt-[0px] xl:pt-[40px] pb-[60px] xl:pb-0">
            <h1 className="text-[#222]  text-[30px] md:text-[36px] xl:text-[40px] font-medium w-fit-content">
              Improving Every Aspect of Accounts Payable
              <span className="text-[#00D588]">.</span>
            </h1>
            <p className="leadinf-[150%] pt-[40px] w-fit-content text-[#222] font-medium text-[14px] md:text-[16px] xl:text-[18px]">
              Is bill line item data entry overwhelming?
            </p>
            <p className="font-[300] leadinf-[200%]  w-fit-content text-[#222] pt-[10px]  text-[14px] md:text-[16px] xl:text-[18px]">
              eliminate the need for any manual data entry with DocuSynthAi's
            </p>
          </div>
        </Fade>
        <div className="w-full xl:w-[65%] bg-[#0048620d]  pt-[40px] px-[50px] pb-[94px] rounded-[10px] flex flex-wrap">
          <div className="flex items-start flex-wrap  justify-between">
            <Fade right delay={100}>
              <div className="w-full md:w-[48%] xl:w-[42%]">
                <div className="bg-[#004862] rounded-[10px] w-[54px] h-[54px]   flex justify-center items-center ">
                  <h5 className="text-[#00D588] font-[600] text-[22px] ">
                    <span className="text-white font-[600] text-[22px] pr-[2px]">
                      0
                    </span>
                    1
                  </h5>
                </div>
                <div className="pt-[28px]">
                  <h1 className="text-[#004862] text-[14px] md:text-[18px] xl:text-[20px] font-[600] tracking-wide">
                    Instant bill data capture
                  </h1>
                  <p className=" w-fit-content text-[12px] md:text-[14px]  xl:text-[16px] font-light text-[#222] pt-2.5">
                    DocuSynth integrates with QuickBooks Online and Desktop. We
                    sync more data, and in a more useful way, than any other
                    accounts payable tool.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade right delay={200}>
              <div className="w-full md:w-[48%] xl:w-[42%]  pt-[40px] md:pt-0">
                <div className="bg-[#004862] rounded-[10px] w-[54px] h-[54px]   flex justify-center items-center ">
                  <h5 className="text-[#00D588] font-[600] text-[22px] ">
                    <span className="text-white font-[600] text-[22px] pr-[2px]">
                      0
                    </span>
                    2
                  </h5>
                </div>
                <div className="pt-[28px]">
                  <h1 className="text-[#004862] text-[14px] md:text-[18px] font-[600] tracking-wide">
                    Approval & authorization
                  </h1>
                  <p className="w-fit-content text-[12px] md:text-[14px]  xl:text-[16px] font-light text-[#222] pt-2.5">
                    Gain a centralized AP repository for your team. Easily
                    auto-forward bills or receipts to their dedicated inbox, and
                    MakersHub will process them.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="flex items-start flex-wrap justify-between pt-[40px] xl:pt-[100px]">
            <Fade right delay={300}>
              <div className="w-full md:w-[48%] xl:w-[42%]">
                <div className="bg-[#004862] rounded-[10px] w-[54px] h-[54px]   flex justify-center items-center ">
                  <h5 className="text-[#00D588] font-[600] text-[22px] ">
                    <span className="text-white font-[600] text-[22px] pr-[2px]">
                      0
                    </span>
                    3
                  </h5>
                </div>
                <div className="pt-[28px]">
                  <h1 className="text-[#004862] text-[14px] md:text-[18px] font-[600] tracking-wide">
                    Mapping to QuickBooks
                  </h1>
                  <p className="w-fit-content text-[12px] md:text-[14px]  xl:text-[16px] font-light text-[#222] pt-2.5">
                    Configure mapping rules one time, and ensure correct coding
                    forever. Auto-create new items or expenses & assign to the
                    Chart of Accounts.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade right delay={100}>
              <div className="w-full md:w-[48%] xl:w-[42%] pt-[40px] md:pt-0">
                <div className="bg-[#004862] rounded-[10px] w-[54px] h-[54px]   flex justify-center items-center ">
                  <h5 className="text-[#00D588] font-[600] text-[22px] ">
                    <span className="text-white font-[600] text-[22px] pr-[2px]">
                      0
                    </span>
                    4
                  </h5>
                </div>
                <div className="pt-[28px]">
                  <h1 className="text-[#004862] text-[14px] md:text-[18px] font-[600] tracking-wide">
                    Embedded payments
                  </h1>
                  <p className="w-fit-content text-[12px] md:text-[14px]  xl:text-[16px] font-light text-[#222] pt-2.5">
                    Collaborate with full context to streamline the review
                    process. Add notes or documents, and notify your team when
                    ready.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Improving;
